.Frequently_second {
  width: 85%;
  margin: auto;
}
.Frequently_box {
  display: flex !important;
  justify-content: space-between !important;
  align-items: flex-end !important;
}
.Frequently_colmn2 {
  /* */
  width: 100%;
  height: 520px;
  border-radius: 30px;
  text-align: right;
  margin-left: auto;
}
.Frequently_img {
  width: 100%;
  height: 100%;
}
.Frequently_h1 {
  font-size: 40px;
  font-weight: 400;
}
.Frequently_p {
  font-size: 20px;
  font-weight: 400;
}
.Frequently_have {
  margin-top: 50px !important;
}
.Frequently_btn1,
.Frequently_btn2,
.Frequently_btn3 {
  border: none;
  width: 70%;
  background: #ffb000;
  border-radius: 14px;
  font-weight: 400;
  font-size: 18px;
  padding: 15px 0px;
  color: #fff;
  margin-bottom: 10px;
}
.Frequently_btn2 {
  width: 60%;
  border: 1px solid #dfdfdf;
  background: #ffffff;
  color: #8e8e93;
}
.Frequently_btn3 {
  border: 1px solid #dfdfdf;
  background: #ffffff;
  color: #8e8e93;
}
.have_input_field {
  position: relative;
  margin-bottom: 40px;
}
.have_input {
  width: 100%;
  height: 60px;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 14px;
  padding-left: 60px;
  color: #000000;
}
.searchIcon {
  position: absolute;
  left: 20px;
  top: 30%;
}
.have_h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 15px;
}
.haveSpan {
  color: #ffb000;
}
.have_dropdown_div {
  position: relative;
  border-bottom: 1px solid #dfdfdf;
}
.closeIcons {
  position: absolute;
  right: 0;
  top: 20px;
  cursor: pointer;
}
.have_dropdown_title {
  padding: 20px 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  cursor: pointer;
}
.have_dropdown_p {
  width: 90%;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #8e8e93;
  padding-bottom: 20px;
}
@media screen and (min-width: 60px) and (max-width: 800px) {
  .Frequently_h1 {
    font-size: 22px;
    font-weight: 500;
  }
  .Frequently_p {
    font-size: 16px;
  }
  .Frequently_colmn2 {
    height: 300px;
  }
  .Frequently_have {
    margin-top: 15px !important;
  }
  .Frequently_btn1,
  .Frequently_btn2,
  .Frequently_btn3 {
    border: none;
    width: 70%;
    font-size: 16px;
    padding: 8px 0px;
  }
  .have_dropdown_title {
    padding: 14px 23px;
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
  }
}
