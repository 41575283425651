.Home_Section {
  width: 88%;
  margin: auto;
  display: flex;
  position: relative;
  align-items: center;
  height: 100vh ; 
}
.bike_img {
  position: absolute;
  top: -21.5% !important;
  width: 65vw !important;
  height: 115vh !important;
  z-index: -1 !important;
}
.backgroundImage_div {
  width: 50%;
  text-align: left;
}
.home_img {
  width: 100%;
  margin-top: 8%;
}

.home_img2 {
  display: none;
}

.home_p {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  width: 100%;
  padding: 0 0 35px 0;
}

.Feeling_hungry {
  font-weight: 800;
  font-size: 80.65px; 
  line-height: 83px;
  color: #ffb000;
  width:100%;
  text-align: left;
}
.CallAction_btn {
width: 150px;
height: 25px;
margin-left: 10px;
margin-top: 20px;
cursor: pointer;
font-family: 'Lato';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 25px;
text-decoration-line: underline;
color: #000000;
}
.drive_bike{
  height: 100%;
  width: 60%;
  top: -18.5%;
  left: 45%;
  position: absolute !important;
}
/* component  */
.Delivery_center_div {
  width: 90%;
  margin: auto;
  text-align: center;
}

.Delivery_content {
  margin-top: 35px;
  font-weight: 100;
}
.Delivery_colmn_img {
  width: 49%;
}
.Delivery_image {
  width: 100%;
}
.Delivery_h2 {
  font-size: 2.5vw;
  font-weight: 100;
}
.collection{
  display: flex;
}
.apple{
  width: 60px;
  height: 55px;
  margin-left: 12px;
}
.playstore{
  width: 60px;
  height: 55px;
  margin-left: 12px;
}

.delivery_p {
  font-size: 20px;
  font-weight: 400;
  color: #a7a7a7;
  font-weight: 400;
  padding: 2vw 0;
}
.read-or-hide {
  color: rgb(192, 192, 192);
  cursor: pointer !important;
}
.bike_img1 {
  display: none;
}
.headerCard_card_title
{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 17px;
  margin-top: 20px;
  letter-spacing: 0.12em;
  color: #000000;
  margin-left: 20px;

}
.headerCard_card_p
{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  margin-top: 20px;
  margin-left: 16px;
color: #A7A7A7;
}

.backimg{
  display: none;
}
.drive_bike1{
  display: none;
}
.home_p{
  font-size: 23px;
}
@media screen and  (max-width: 420px) {
  .backgroundImage_div {
    margin-top: 55vh;
}
  .text {
    font-size: 16px !important;
    font-weight: 400;
    display: inline;
    width: 100%;
  }
  .Home_Section {
    width: 100%;
    height: inherit;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .backgroundImage_div{
    width: 100%;
    height: 50%;
    position: relative;
    margin-top: 70vh;

    text-align: center;
    flex-direction: column;
    
  }
  .drive_bike{
    display: none;
    position: absolute !important;
    left: 0;
    height: 100% !important;
    width: 100% !important;
  }
.drive_bike1{
  display: block;
  height: 60% !important;
  width: 100% !important;
  position: absolute;
  overflow: hidden;
}
.backgroundImage_div {
  margin-top: 55vh !important;
}
.bike_img1{
  /* height: 110%; */
  width: 120%;
  display: block;
  top: -10% !important;
  position: absolute;
}
  .bike_img{
    display: none;
    width: 150% !important;
    height: 80% !important;
    margin-top: 35%;
    margin-left: -70% !important;
    position: relative;
  }
 
  .Feeling_hungry {
    width: 100%;
    font-size: 26.65px;
    line-height: 35px;
    text-align: center;
  }
  .home_p {
    width: 100%;
  }
  .CallAction_btn {
    width: 181px;
    height: 54px;
  margin-left: 0;

  }
  .nav_menu_div {
    justify-content: center;
    display: none !important;
  }
  .apple{
    width: 60px;
    height: 55px;
    margin-left: 12px;
  }
  .playstore{
    width: 60px;
    height: 55px;
    margin-left: 12px;
  }
  .collection{
    display: flex;
    justify-content: center;
  }
  
}
@media screen and (min-width:321px) and (max-width: 640px) {
  .text {

    font-size: 16px !important;
    font-weight: 400;
    display: inline;
    width: 100%;
  }
  .Home_Section {
    width: 100%;
    height: inherit;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .backgroundImage_div{
    width: 100%;
    height: 50%;
    position: relative;
    margin-top: 70vh;
    padding: 0 10px;
    text-align: center;
    flex-direction: column;
  }
  .drive_bike{
    display: none;
    position: absolute !important;
    left: 0;
    height: 100% !important;
    width: 100% !important;
  }
.drive_bike1{
  display: block;
  height: 60% !important;
  width: 90% !important;
  position: absolute;
  overflow: hidden;
}

.bike_img1{
  height: 97%;
  width: 106%;
  display: block;
  top: -10% !important;
  position: absolute;
}
  .bike_img{
    display: none;
    width: 150% !important;
    height: 80% !important;
    margin-top: 35%;
    margin-left: -70% !important;
    position: relative;
  }
  .Feeling_hungry {
    width: 100%;
    font-size: 26.65px;
    line-height: 35px;
    text-align: center;
  }
  .home_p {
    width: 100%;
  }
  .CallAction_btn {
    width: 181px;
    height: 54px;
  margin-left: 0;

  }
  .nav_menu_div {
    justify-content: center;
    display: none !important;
  }
  .apple{
    width: 60px;
    height: 55px;
    margin-left: 12px;
  }
  .playstore{
    width: 60px;
    height: 55px;
    margin-left: 12px;
  }
  .collection{
    display: flex;
    justify-content: center;
  }

}
@media screen and (min-width:551px) and (max-width: 800px) {
  .text {
    font-size: 16px !important;
    font-weight: 400;
    display: inline;
    width: 100%;
  }
  .Home_Section {
    width: 100%;
    height: inherit;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .backgroundImage_div{
    width: 100%;
    height: 50%;
    position: relative;
    margin-top: 80vh;

    text-align: center;
    flex-direction: column;
  }
  .drive_bike{
    display: none;
    position: absolute !important;
    left: 0;
    height: 100% !important;
    width: 100% !important;

  }
.drive_bike1{
  display: block;
  height: 70% !important;
  width: 100% !important;
  position: absolute;
  margin: 0;
  overflow: hidden;
}


.bike_img1{
  height: 96%;
  width: 100%;
  display: block;
  top: -10% !important;
  position: absolute;
}
  .bike_img{
    display: none;
    width: 150% !important;
    height: 80% !important;
    margin-top: 35%;
    margin-left: -70% !important;
    position: relative;
  }
  .Feeling_hungry {
    width: 100%;
    font-size: 26.65px;
    line-height: 35px;
    text-align: center;
  }
  .home_p {
    width: 100%;
  }
  .CallAction_btn {
    width: 181px;
    height: 54px;
  margin-left: 0;
  }
  .nav_menu_div {
    justify-content: center;
    display: none !important;
  }
  .apple{
    width: 60px;
    height: 55px;
    margin-left: 12px;
  }
  .playstore{
    width: 60px;
    height: 55px;
    margin-left: 12px;
  }
  .collection{
    display: flex;
    justify-content: center;
  }
}
@media screen and (min-width: 801px) and (max-width: 900px) {
  .Feeling_hungry {
    font-size: 48.65px;
    line-height: 66px;
    width: 82%;
  }
  .bike_img {
    top: -10% !important;
    height: 690px !important;
  }
  .Home_Section {
    height: 91vh;
  }
  .navLogo {
    width: 9rem;
  }
  .apple{
    width: 60px;
    height: 55px;
    margin-left: 12px;
  }
  .playstore{
    width: 60px;
    height: 55px;
    margin-left: 12px;
  }
}
@media screen and (min-width: 901px) and (max-width: 1000px) {
  .Feeling_hungry {
    font-size: 48.65px;
    line-height: 66px;
    width: 82%;
  }
  .bike_img {
    top: -5% !important;
    height: 710px !important;
  }
  .Home_Section {
    height: 91vh;
  }
  .navLogo {
    width: 9rem;
  }
  .apple{
    width: 60px;
    height: 55px;
    margin-left: 12px;
  }
  .playstore{
    width: 60px;
    height: 55px;
    margin-left: 12px;
  }


}
 @media screen and (min-width: 1001px) and (max-width: 1100px) {
  .Feeling_hungry {
    font-size: 48.65px;
    line-height: 66px;
    width: 82%;
  }
   .bike_img {
     top: -14% !important;
    right:  -6% !important;
    height: 790px !important;
  } 
  .Home_Section {
    height: 91vh;
  }
  .navLogo {
    width: 9rem;
  }
  .apple{
    width: 60px;
    height: 55px;
    margin-left: 12px;
  }
  .playstore{
    width: 60px;
    height: 55px;
    margin-left: 12px;
  }
} 
@media screen and (min-width: 1101px) and (max-width: 1250px) {
  .drive_bike {
    width: 60%;
    top: -47.5%;
    left: 57%;
}
  .Feeling_hungry {
    font-size: 42.65px;
    line-height: 66px;
    width: 82%;
  }
   .bike_img {
     top: -7% !important;
    right:  -6% !important;
    height: 790px !important;
  } 
  .Home_Section {
    height: 70vh;
  }
  .navLogo {
    width: 9rem;
  }
  .apple{
    width: 60px;
    height: 55px;
    margin-left: 12px;
  }
  .playstore{
    width: 60px;
    height: 55px;
    margin-left: 12px;
  }
} 
@media screen and (min-width: 1251px) and (max-width: 1400px) {
  .nav_menu_div{
    font-size: 20px;
  }
  .Feeling_hungry {
    font-size: 58.65px;
    line-height: 66px;
    width: 82%;
  }
  .Home_Section {
    height: 91vh;
  }
  .navLogo {
    width: 9rem;
  }
  .apple{
    width: 80px;
    height: 75px;
    margin-left: 12px;
  }
  .playstore{
    width: 80px;
    height: 75px;
    margin-left: 12px;
  }
}
@media screen and (min-width: 1401px) and (max-width: 1600px) {
  .nav_menu_div{
    font-size: 30px;
  }
  .Feeling_hungry {
    font-size: 58.65px;
    line-height: 66px;
    width: 82%;
  }
  .bike_img {
    top: -5% !important;
    right: -6% !important;
    height: 890px !important;
  }
  .Home_Section {
    height: 95vh;
  }
  .drive_bike {
    width: 60%;
    top: -47.5%;
    left: 57%;
}
  .navLogo {
    width: 9rem;
  }
  .apple{
    width: 90px;
    height: 85px;
    margin-left: 12px;
  }
  .playstore{
    width: 90px;
    height: 85px;
    margin-left: 12px;
  }
  .home_p{
    font-size: 30px;
    line-height: 40px;

  }
} 
@media screen and (max-width:800px) {
  .Delivery_content{
    margin: 0;
    display: none;
  }

  .Delivery_image_div{
    margin-top: -25%;
  }
}
@media screen and (min-width:801px) and (max-width:880px) {
  .Delivery_h2{
  font-weight: 300;
  font-size: 30px;
}
}
