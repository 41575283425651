.form_main {
  width: 100%;
  margin-top: 30px;
}
.form_second_div {
  width: 85%;
  margin: auto;
}
.contact_us {
  width: 90%;
}
.contact_h1 {
  font-size: 40px;
  font-weight: 400;
}
.contact_p {
  font-size: 20px;
  font-weight: 400;
  width: 80%;
  padding-bottom: 15px;
}
.contect_label {
  padding-bottom: 0px;
  font-size: 20px;
  font-weight: 400;
  padding-top: 25px;
}
.inputfields {
  width: 100%;
  padding: 15px 10px;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 14px;
}
.inputfields_number {
  width: 100%;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-top-right-radius: 14px !important;
  border-bottom-right-radius: 14px !important;
}
.contactTextarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #dfdfdf;
  border-radius: 14px;
  height: 260px;
}
.contact_submit {
  width: 100%;
  padding: 10px;
  background: #ffb000;
  border-radius: 10px;
  border: none;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
}
.country_feilds {
  display: flex;
}
.coutry_dropdown {
  border: none !important;
  border: 1px solid #dfdfdf !important;
  border-top-left-radius: 14px !important;
  border-bottom-left-radius: 14px !important;
  padding: 10px !important;
  outline: none !important;
}
.css-1ptx2yq-MuiInputBase-root-MuiInput-root:before {
  border-bottom: inherit !important;
}
.css-1ptx2yq-MuiInputBase-root-MuiInput-root:after {
  border-bottom: inherit !important;
}
.contact_map {
  width: 100%;
  height: 100vh;
}
.contact_map_p {
  text-decoration-line: underline;
  color: #ffb000;
  font-size: 19px;
  font-weight: 700;
  margin-top: 15px;
}
.Support_main {
  margin-top: 100px;
}
.support_h2 {
  font-size: 40px;
  font-weight: 400;
  margin-bottom: 15px;
  padding-left: 15px;
}
.support_p {
  font-size: 18px;
  font-weight: 400;
  width: 80%;
  padding-left: 15px;
}
.Support_img {
  margin: 0 0 -70px 0;
}
.Support_box {
  margin-bottom: 100px;
}
@media screen and (max-width: 900px) {
  .contact_us {
    width: 100%;
  }
  .contact_h1 {
    font-size: 25px;
  }
  .contact_p {
    font-size: 15px;
    width: 100%;
    padding-bottom: 5px;
  }
  .contect_label {
    font-size: 15px;
    padding-top: 21px;
  }
  .inputfields {
    padding: 8px 7px;
    border-radius: 7px;
    font-size: 13px;
  }
  .inputfields_number {
    padding: 8px 7px;
    font-size: 13px;
    border-top-right-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
  }
  .coutry_dropdown {
    border-top-left-radius: 7px !important;
    border-bottom-left-radius: 7px !important;
    padding: 0px 7px !important;
    width: 70%;
  }
  .form_colmn_2 {
    margin-top: 50px;
  }
  .contact_submit {
    border-radius: 10px;
    font-size: 15px;
  }
  .contact_map_p {
    font-size: 13px;
  }
  .Support_main {
    margin-top: 40px;
  }
  .support_h2 {
    font-size: 25px;
  }
  .support_p {
    font-size: 15px;
    width: 100%;
  }
  .Support_box {
    margin-bottom: 40px;
  }
}
