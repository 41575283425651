.Maps_headings {
  text-align: center;
  padding: 2% 0 5% 0;
  width: 90%;
  margin: auto;
}

.Maps_headings2 {
  text-align: center;
  padding: 3% 0 0% 0;
  width: 90%;
  margin: auto;
}

.Maps_h1 {
  font-size: 3vw;
  font-weight: 100;
}

.Maps_h12 {
  font-size: 40px;
  font-weight: 300;
  word-spacing: 5px;
  letter-spacing: 5px;
}

.maps_p {
  color: #a7a7a7;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
}

.map_image {
  text-align: center;
  width: 80%;
}

.maps_img {
  width: 51vw;
}

.OurStory {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.OurStory_P {
  font-size: 40px;
  line-height: 66px;
  letter-spacing: 0.12em;
  color: #000000;
  font-weight: 300;
}

._things {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.great_things_P {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #a7a7a7;
}

.MapMainDiv {
  height: 120vh;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.mapImgDiv {
  height: 90%;
  position: absolute;
  left: 15%;
  top: 5%;
}

.mapImg {
  height: 100%;
}

.divone {
  height: 40%;
  position: absolute;
  width: 100%;
}

.divtwo {
  position: absolute;
  z-index: -1;
  height: 60% !important;
  width: 100%;
  background-color: #f6f6f6;
  top: 40%;
}

.WhyReilusMain {
  width: 100%;
  box-sizing: border-box;
  background-color: #f6f6f6;
  margin-top: 50px auto !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.WhyReilusMainBox {
  width: 85%;
  height: 90%;
}

.WhyReiluText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 25px;
  margin-left: 5%;
  color: #000000;
}

.WhyReiluTextP {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  width: 80%;
  color: #a7a7a7;
  margin-left: 5%;
  padding: 16px 0;
}

.tree_main {
  position: relative !important;
  height: 66vh;
  background-color: #ffffff;
  overflow: hidden;
}

.tree {
  background: #008000;
  bottom: 0% !important;
  width: 100%;
  padding: 3% 5%;
  position: absolute !important;
}

.com {
  width: 100% !important;
  height: 68px !important;
}

.com_p {
  padding-top: 15px;
  font-size: 15.5px !important;
  font-weight: 400 !important;
}

.root {
  width: 40% !important;
  right: 5%;
  position: absolute !important;
  bottom: -1px;
}

.rootimg {
  width: 100%;
  height: 100%;
}

.Raduis {
  border-radius: 0px 30px 30px 0px;
}

.watchme {
  margin-left: 5%;
  margin-right: 10px;
  height: 66px;
}

.watchNow {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #FFB000;
}

.foodImginWhyReilusection {
  width: 100%;
  height: 646px;
  margin-left: auto;
  display: block;
  border-radius: 0px 30px 30px 0px !important;
}

.col_white_color1 {
  background: #ffffff;
  padding: 20px 0 !important;
  width: 100%;
  height: auto;
  border-radius: 30px 0px 3px 30px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.col_white_color2 {
  background: #ffffff;
  border-radius: 0px 30px 30px 0px;
  padding: 0 !important;
}

@media screen and (min-width: 2181px) and (max-width: 2580px) {
  .WhyReilu_div {
    height: 105vw;
    margin: -109vh auto 0 auto;
  }
}

@media screen and (min-width: 1881px) and (max-width: 2180px) {
  .WhyReilu_div {
    height: 105vw;
    margin: -84vh auto 0 auto;
  }
}

@media screen and (min-width: 1581px) and (max-width: 1880px) {
  .WhyReilu_div {
    height: 105vw;
    margin: -84vh auto 0 auto;
  }
}

@media screen and (min-width: 1381px) and (max-width: 1580px) {
  .WhyReilusMain {
    margin-top: 0% !important;
  }
}

@media screen and (min-width: 1181px) and (max-width: 1380px) {
  .WhyReilu_div {
    height: 197vh;
    margin: -84vh auto 0 auto;
  }

  .mapImgDiv {
    position: absolute;
    left: 12%;
    top: 5%;
    width: 50%;
  }

  .tree {
    width: 100%;
    /* height: 95px; */
    position: relative
  }

  .com {
    height: 44px;
  }

  .com_p {
    font-size: 13px;
    font-weight: 300;
  }
}

@media screen and (min-width: 801px) and (max-width: 1180px) {
  .WhyReilu_div {
    height: 125vh;
    margin: -54vh auto 0 auto;
  }

  .mapImgDiv {
    position: absolute;
    left: 9%;
    top: 5%;
    width: 53%;
  }

  .mapImg {
    width: 100%;
  }

  .WhyReilu_colmn_images {
    width: 5rem;
  }

  .WhyReilu_colmn {
    width: 100%;
    border-radius: 0;
    background: none;
  }

  .home_p {
    top: 17%;
    font-size: 22px;
  }

  .home_img {
    width: 100%;
    margin-top: 14%;
  }

  .Delivery_h2 {
    font-size: 30px;
  }

  .WhyReiluText {
    font-size: 40px;
  }

  .text {
    font-size: 18px !important;
  }

  .WhyReilusMainBox {
    width: 92%;
  }

  .foodImginWhyReilusection {
    width: 100%;
    height: 400px;
    border-radius: 0px 30px 30px 0px !important;
    margin: auto;
  }
}

@media screen and (min-width:801px) and (max-width:1180px) {
  .tree_main {
    height: 44vh;
  }

  .tree {
    width: 100%;
    position: relative
  }

  .com {
    height: 44px;
  }

  .com_p {
    font-size: 12px;
    font-weight: 200;
  }

  .root {
    margin-top: 0px;
    margin-left: 403px;
    width: 326px;
    position: absolute;
  }
}

@media screen and (min-width: 681px) and (max-width: 768px) {
  .tree_main {
    height: 40vh;
  }

  .WhyReilu_div {
    margin: -51vh auto 0 auto;
    height: 179vh;
    text-align: center;
  }

  .OurStory_P {
    font-size: 1.5rem;
    line-height: 66px;
    letter-spacing: 0.12em;
    color: #000000;
    font-style: normal;
    font-weight: 300;
  }

  .mapImg {
    height: 100%;
    width: 81%;
  }

  .WhyReilu_colmn_images {
    width: 8rem;
    display: none;
  }

  .Maps_h1 {
    font-size: 4vw;
  }

  .maps_p {
    font-size: 2.5vw;
  }

  .WhyReilu_colmn {
    display: flex;
    width: 100%;
    background: none;
    border-radius: 0px;
  }

  .WhyReilu_colmn_h2 {
    font-size: 4vw;
  }

  .WhyReilu_colmn_p {
    font-size: 2vw;
    padding: 2vw 0;
  }

  .maps_p {
    font-size: 2.5vw;
  }

  .WhyReilu_center_main_div {
    flex-direction: column;
  }

  .tree {
    width: 100%;
    /* height: 95px; */
    position: relative
  }

  .com {
    height: 44px;
    /* background-image: url("../../assets/Image/Compress.png");
  background-size: 100% 100%; */
  }

  .com_p {
    font-size: 12px;
    font-weight: 200;
  }

  .root {
    margin-top: 0px;
    margin-left: 403px;
    position: absolute;
    width: 49% !important;
    right: -9%;
    bottom: 1px;
  }

  .watchme {
    display: none !important;
  }

  .foodImginWhyReilusection {
    width: 95%;
    height: 275px;
    border-radius: 30px 30px 30px 30px !important;
    margin: auto;
  }

}

@media screen and(min-width:769px) and (max-width:800px) {
  .com {
    height: 44px;
  }

}

@media screen and (min-width: 481px) and (max-width: 680px) {
  .watchme {
    display: none;
  }

  .watchNow {
    display: none;
  }

  .great_things_P {
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 0px;
    text-align: center;
    color: #a7a7a7;
  }

  .OurStory_P {
    font-size: 1.2rem;
    line-height: 66px;
    letter-spacing: 0.12em;
    color: #000000;
    font-style: normal;
    font-weight: 300;
  }

  .divtwo {
    width: 100%;
    background-color: #f6f6f6;
    top: 44%;
  }

  .mapImg {
    height: 100%;
    width: 81%;
  }

  .WhyReilu_div {
    margin: -39vh auto 0 auto;
    height: 147vh;
    text-align: center;
  }

  .WhyReilu_colmn_images {
    width: 8rem;
    display: none;
  }

  .Maps_h1 {
    font-size: 4vw;
  }

  .maps_p {
    font-size: 2.5vw;
  }

  .WhyReilu_colmn {
    display: flex;
    width: 100%;
    background: none;
    border-radius: 0px;
  }

  .WhyReilu_colmn_h2 {
    font-size: 4vw;
  }

  .WhyReilu_colmn_p {
    font-size: 3.5vw;
    padding: 2vw 0;
  }

  .maps_p {
    font-size: 14px;
  }

  .WhyReilu_center_main_div {
    flex-direction: column;
  }

  .tree {
    width: 100%;
    /* height: 95px; */
    position: relative;
    padding: 16px 5%;
  }

  /* .com{
  background-image: url("../../assets/Image/Compress.png");
  background-size: 100% 100%;
  } */
  .com_p {
    font-size: 15px !important;
    font-weight: 200;
  }

  .root {
    margin-top: 0px;
    margin-left: 230px;
    width: 67% !important;
    right: -20%;
    /* height: 154px; */
    position: absolute;
  }

  .foodImginWhyReilusection {
    width: 95%;
    height: 250px;
    border-radius: 30px 30px 30px 30px !important;
    margin: auto;
  }

  .tree_main {
    height: 46vh;
  }
}

@media screen and (min-width: 321px) and (max-width: 480px) {
  .DriversCircle>img {
    width: 90px;
    height: 90px;
  }

  .watchme {
    display: none;
  }

  .watchNow {
    display: none;
  }

  .great_things_P {
    font-weight: 400;
    font-size: 14px;
    line-height: 0px;
    text-align: center;
    color: #a7a7a7;
  }

  .OurStory_P {
    font-size: 19px;
    line-height: 66px;
    letter-spacing: 0.12em;
    color: #000000;
    font-style: normal;
    font-weight: 300;
  }

  .divtwo {
    background-color: #f6f6f6;
    width: 100%;
    top: 37%;
  }

  .WhyReilusMain {
    background-color: #f6f6f6;
    margin-top: -3%;
  }

  .mapImg {
    height: 100%;
    width: 81%;
  }

  .WhyReilu_div {
    margin: -27vh auto 0 auto;
    height: 108vh;
    text-align: center;
  }

  .WhyReilu_colmn_images {
    width: 8rem;
    display: none;
  }

  .Maps_h1 {
    font-size: 19px;
  }

  .maps_p {
    font-size: 14px;
  }

  .WhyReilu_colmn {
    display: flex;
    width: 100%;
    background: none;
    border-radius: 0px;
  }

  .WhyReilu_colmn_h2 {
    font-size: 27px;
  }

  .WhyReilu_colmn_p {
    font-size: 12px;
    padding: 2.5vw;
  }

  .maps_p {
    font-size: 14px;
  }

  .tree {
    width: 100%;
    /* height: 95px; */
    position: relative;
    /* padding: 14px 0%; */
  }

  .tree_main {
    height: 32vh;
  }

  .com {
    height: 40px !important;
    /* background-size: 100% 100%; */
  }

  .com_p {
    width: 50%;
    font-size: 12px !important;
    font-weight: 200;
    /* padding-top: 0; */
  }

  .root {
    margin-top: 0px;
    margin-left: 230px;
    width: 70% !important;
    right: -18%;
  }

  .WhyReilu_center_main_div {
    flex-direction: column;
    border-radius: none !important;
    box-shadow: none !important;
    border: none !important;
  }

  .WhyReilu_colmn2 {
    display: flex;
    width: 100%;
    margin: 0 auto 0 auto;
    align-items: center;
    background-color: #fff;
    border-radius: 0px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    margin-top: -3%;
  }

  .foodImginWhyReilusection {
    width: 95%;
    height: 250px;
    border-radius: 30px 30px 30px 30px !important;
    margin: auto;
  }

}

@media screen and (min-width: 271px) and (max-width: 360px) {
  .great_things_P {
    font-weight: 400;
    font-size: 14px;
    line-height: 0px;
    text-align: center;
    color: #a7a7a7;
  }

  .OurStory_P {
    font-size: 19px;
    line-height: 66px;
    letter-spacing: 0.12em;
    color: #000000;
    font-style: normal;
    font-weight: 300;
  }

  .watchme {
    display: none;
  }

  .WhyReilusMain {
    margin-top: -3%;
  }

  .foodImginWhyReilusection {
    width: 95%;
    height: 250px;
    border-radius: 30px 30px 30px 30px !important;
    margin: auto;
  }

  .WhyReilu_div {
    margin: -27vh auto 0 auto;
    height: 92vh;
    text-align: center;
  }

  .WhyReilu_colmn_images {
    width: 6rem;
    display: none;
  }

  .Maps_h1 {
    font-size: 19px;
  }

  .maps_p {
    font-size: 2.5vw;
  }

  .WhyReilu_colmn {
    display: flex;
    width: 100%;
    background: none;
    border-radius: 0px;
  }

  .WhyReilu_colmn_h2 {
    font-size: 4vw;
  }

  .WhyReilu_colmn_p {
    font-size: 3.5vw;
    padding: 2vw 0;
  }

  .maps_p {
    font-size: 14px;
  }

  .WhyReilu_center_main_div {
    flex-direction: column;
    border-radius: none !important;
    box-shadow: none !important;
    border: none !important;
  }

  .Maps_headings {
    text-align: center;
    padding-bottom: 3.5vw;
  }

  .mapImg {
    height: 100%;
    width: 80%;
  }

  .tree {
    width: 100%;
    position: relative
  }

  .com {
    /* height: 45px !important; */
  }

  .com_p {
    font-size: 12px !important;
    font-weight: 200;
  }

  .root {
    margin-top: 0px;
    margin-left: 230px;
    width: 237px;
    position: absolute;
  }
}

@media screen and (min-width:320px) and (max-width:450px) {
  .WhyReiluText {
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    margin: 0;
  }

  .WhyReiluTextP {
    text-align: center;
    font-size: 14px !important;
  }

  .col_white_color1 {
    width: 100%;
    border-radius: 0% !important;
    background-color: #f6f6f6;
  }

  .col_white_color2 {
    width: 100%;
    border-radius: 0% !important;
    background-color: #f6f6f6;
  }

  .whymain {
    text-align: center;
  }
}

@media screen and (min-width:451px) and (max-width:550px) {
  .WhyReiluText {
    text-align: center;
    font-size: 25px;
    font-weight: 300;
    margin: 0;
  }

  .WhyReiluTextP {
    text-align: center;
    font-size: 18px !important;
  }

  .col_white_color1 {
    width: 100%;
    border-radius: 0% !important;
    background-color: #f6f6f6;
  }

  .col_white_color2 {
    width: 100%;
    border-radius: 0% !important;
    background-color: #f6f6f6;
  }

  .watchme {
    display: none;
  }
}

@media screen and (min-width:551px) and (max-width:650px) {

  .WhyReiluText {
    text-align: center;
    font-size: 25px;
    font-weight: 300;
    margin: 0;
  }

  .WhyReiluTextP {
    text-align: center;
    font-size: 18px !important;
  }

  .col_white_color1 {
    width: 100%;
    border-radius: 0% !important;
    background-color: #f6f6f6;
  }

  .col_white_color2 {
    width: 100%;
    border-radius: 0% !important;
    background-color: #f6f6f6;
  }

  .watchme {
    display: none;
  }
}

@media screen and (min-width:651px) and (max-width:769px) {
  .WhyReiluText {
    text-align: center;
    font-size: 28px;
    font-weight: 300;
    margin: 0;
  }

  .WhyReiluTextP {
    text-align: center;
    font-size: 20px !important;
  }

  .col_white_color1 {
    width: 100%;
    border-radius: 0% !important;
    background-color: #f6f6f6;
  }

  .col_white_color2 {
    width: 100%;
    border-radius: 0% !important;
    background-color: #f6f6f6;
  }

  .watchme {
    display: none;
  }
}

@media screen and (min-width: 320px) and (max-width: 780px) {


  .MapMainDiv {
    height: 70vh !important;
  }
}