.RestSection {
  width: 100%;
}

.RestSection_center {
  width: 90%;
  margin: auto;
  background: url("../../assets/Image/ResBg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  height: 116vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-radius: 30px;
}

.RestSection_heading {
  color: #fff;
  margin: 4% 0;

}

.RestSection_h1 {
  font-size: 3rem;
  text-align: center;
  /* font-size: 40px; */
  font-weight: 700;
  margin: 0 0 10% 0;
  letter-spacing: 0.4ch;
}

.RestSection_box {
  border: 1px solid blue;
  background: rgba(255, 255, 255, 0.7);
  /* border: 2px solid #ffffff; */
  backdrop-filter: blur(31px);
  color: black;
  border-radius: 20px;
  display: flex;
  align-items: center;
  width: 90%;
  margin: auto;
  padding: 1% 2% 1% 1.5%;
  border: transparent !important;

}

.RestSection_box_title {
  font-size: 24px;
  font-weight: 400;
  padding-left: -5%;

}

.RestSection_box_p {
  font-size: 20px;
  font-weight: 400;
}

.RestSection_box_content {
  width: 80%;
}

.RestSection_box_btn {
  background-color: #2973cc;
  font-size: 20px;
  font-weight: 500;
  padding: 6% 4%;
  color: #fff;
  border: none;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
}

.RestSection_box_button_div {
  width: 20%;
  display: block;
}

@media screen and (max-width: 800px) {
  .RestSection_center {
    height: 60vh;
  }

  .RestSection_box_p {
    font-size: 10px;
    padding: 10px 0;
  }

  .RestSection_box_title {
    line-height: 22px;
    font-size: 18px;
  }

  .RestSection_box {
    flex-direction: column;
    padding: 2% 4%;
  }

  .RestSection_box_content {
    width: 100%;
  }

  .RestSection_box_button_div {
    width: 50%;
  }

  .RestSection_box_btn {
    font-size: 14px;
    padding: 3% 4%;
  }

  .RestSection_h1 {
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  .RestSection_h1 {
    display: none;
  }
}