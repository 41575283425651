.css-1ami7lv-MuiTimelineConnector-root {
  height: 50vh;
  background-color: #ffb000 !important;
}

.css-1nqcc1s {
  background-color: #ffb000 !important;
}

.css-idv8vo {
  background-color: #ffb000 !important;
  height: 60vh !important;
}

.css-v6mo1y-MuiTimelineDot-root {
  background-color: #ffb000 !important;
  margin: 0 !important;
}

.css-v6mo1y-MuiTimelineDot-root::after{
  height: 20px !important;
  width: 20px !important;
  background-color: #ffb000 !important;
  opacity: 0.2 !important;
  border-radius: 50% !important;
}

.Works_main_div {
  background-color: #fff;

}

.Works_centerDiv {
  padding: 50px 0 50px 0;
  width: 90%;
  margin: auto;
}

.maps_p {
  font-size: 1.3rem;
  line-height: 5px;
  letter-spacing: 1px;
}

.works_timeine_centerDiv {
  margin: 50px 0 30px 0;
}

.works_mobile_div {
  margin-top: -21%;
  height: 43vh;
  /* border: 1px solid black  !important; */

}

.works_mobile_img {
  height: 100%;
  /* width: 170px; */
  margin-left: 20px;
  
}

.works_title {
  font-size: 36px;
  margin-top: -10px;
  color: black;
  font-weight: 300;
  margin-right: 20px !important;
}

.works_title_p {
  width: 50%;
  margin: 9px 0 0 auto;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  color: #a7a7a7;
  /* border: 1px solid black; */
  margin-right: 20px !important;

}

.works_android_div {
  margin-left: 20px;
}

.works_anroid_p {
  color: #a7a7a7;
  font-size: 20px;
  font-weight: 400;
  padding-bottom: 10px;
}

.works_apps {
  margin-right: 18px;
}

@media screen and (min-width: 0px) and (max-width: 270px) {
  .works_title {
    font-size: 11px;
    margin-top: 0px;
  }

  .works_anroid_p {
    font-size: 10px;
  }

  .works_android_div {
    margin-left: 0;
  }

  .works_apps {
    margin-right: 5px;
    width: 50px;
  }

  .works_mobile_img {
    height: 24vh;
    margin-left: 0px;
  }

  .css-1ami7lv-MuiTimelineConnector-root {
    height: 29vh !important;
  }

  .works_mobile_div {
    margin-top: -56%;
  }

  .Works_centerDiv {
    padding: 20px 0 0 0;
  }
.Maps_h12{
  font-size: 20px;
  text-align: center;
}
  .works_timeine_centerDiv {
    margin: 16px 0 8px 0;
  }

  .css-idv8vo {
    height: 27vh !important;
  }

  .works_title_p {
    width: 100% !important;
  }
}

@media screen and (min-width: 271px) and (max-width: 360px) {
  .works_title {
    font-size: 14px;
    margin-top: 0px;
    width: 100%;
  }

  .works_anroid_p {
    font-size: 10px;
  }

  .works_android_div {
    margin-left: 0;
  }

  .works_apps {
    /* margin-right: % !important; */
    /* border: 2px solid blue; */
    width: 72px;
    margin-bottom: 5%;
  }

  .works_mobile_img {
    height: 27vh;
    /* border: 2px solid purple; */
    margin-left: 0px;
    margin-top: 13%;
    /* margin-bottom: 13% !important; */
  }
.Maps_h12{
  font-size: 20px !important;
  text-align: center;
  font-weight: 300;
  /* border: 1px solid blueviolet; */
}
  .css-idv8vo {
    height: 35vh !important;
  }

  .css-1ami7lv-MuiTimelineConnector-root {
    height: 29vh !important;
  }

  .works_mobile_div {
    margin-top: -50%;
    /* border: 3px solid yellow; */
  }

  .Works_centerDiv {
    padding: 20px 0 0 0;
  }

  .works_timeine_centerDiv {
    margin: 16px 0 8px 0;
  }

  .work_download_app_div {
    display: flex;
    flex-direction: column;
  }

  .works_title_p {
    width: 100% !important;
    font-size: 10px;
    line-height: 10px;
    
    /* border: 2px solid black; */
  }
}

@media screen and (min-width: 361px) and (max-width: 440px) {
  .works_title {
    margin-top: 0px;
    font-size: 14px;
    width: 100%;

  }
  .Maps_h12{
    font-size: 25px !important;
    text-align: center;
    font-weight: 300;
    /* border: 1px solid blueviolet; */
  }
  .works_anroid_p {
    font-size: 14px;
  }

  .works_android_div {
    margin-left: 0;
  }

  .works_apps {
    width: 70px;
    margin-bottom: 5%;
  }

  .works_mobile_img {
    height: 28vh;
    margin-left: 0px;
    margin-top: 27%;
  }

  .work_download_app_div {
    display: flex;
    flex-direction: column;
  }

  .css-1ami7lv-MuiTimelineConnector-root {
    height: 29vh !important;
  }

  .works_mobile_div {
    margin-top: -56%;
  }

  .Works_centerDiv {
    padding: 20px 0 0 0;
  }

  .works_timeine_centerDiv {
    margin: 16px 0 8px 0;
  }

  .works_title_p {
    width: 100% !important;
    font-size: 10px;
    line-height: 15px;
  }
}


@media screen and (min-width: 441px) and (max-width: 560px) {
  .works_title {
    margin-top: 0px;
    font-size: 14px;
    width: 100%;

  }
  .Maps_h12{
    font-size: 28px !important;
    text-align: center;
    font-weight: 300;
    /* border: 1px solid blueviolet; */
  }
.work_android_div{
  width: 100% !important;
  /* border: 2px solid blue; */
}
  .works_anroid_p {
    width: 100%;
    /* border: 2px solid yellow; */
    font-size: 20px;
  }

  .works_android_div {
    margin-left: 0;
  }

  .works_apps {
    width: 70px;
    margin-bottom: 5%;
  }

  .works_mobile_img {
    height: 28vh;
    margin-left: 0px;
    margin-top: 30%;
  }

  .work_download_app_div {
    display: flex;
    flex-direction: column;
  }

  .css-1ami7lv-MuiTimelineConnector-root {
    height: 29vh !important;
  }

  .works_mobile_div {
    margin-top: -56%;
  }

  .Works_centerDiv {
    padding: 20px 0 0 0;
  }

  .works_timeine_centerDiv {
    margin: 16px 0 8px 0;
  }

  .works_title_p {
    width: 100% !important;
    font-size: 13px !important;
    line-height: 15px !important;
  }
}



@media screen and (min-width: 561px) and (max-width: 860px) {
  .works_title {
    font-size: 20px;
    margin-top: 0px;
    width: 100%;
    font-weight: 400;
  }

  .maps_p {
    font-size: 1rem;
  }

  .works_anroid_p {
    font-size: 16px;
  }

  .works_android_div {
    margin-left: 0;
  }

  .works_apps {
    margin-right: 5px;
    width: 80px;
  }

  .works_mobile_img {
    height: 31vh;
    margin-left: 0px;
  }

  .css-1ami7lv-MuiTimelineConnector-root {
    height: 31vh !important;
  }

  .works_mobile_div {
    margin-top: -16%;
  }

  .Works_centerDiv {
    padding: 20px 0 0 0;
  }

  .works_timeine_centerDiv {
    margin: 16px 0 8px 0;
  }

  .works_title_p {
    width: 100% !important;
    font-size: 13px !important;
  }
}

@media screen and (min-width: 441px) and (max-width: 560px) {
  .works_title {
    margin-top: 0px;
    font-size: 18px;
    width: 100%;
    font-weight: 400;

/* border: 2px solid black; */
  }
.work_android_div{
  width: 100% !important;
  /* border: 2px solid blue; */
}
  .works_anroid_p {
    width: 100%;
    /* border: 2px solid yellow; */
    font-size: 16px;
  }

  .works_android_div {
    margin-left: 0;
  }

  .works_apps {
    width: 70px;
    margin-bottom: 5%;
  }

  .works_mobile_img {
    height: 28vh;
    margin-left: 0px;
    margin-top: 30%;
  }

  .work_download_app_div {
    display: flex;
    flex-direction: column;
  }

  .css-1ami7lv-MuiTimelineConnector-root {
    height: 29vh !important;
  }

  .works_mobile_div {
    margin-top: -56%;
  }

  .Works_centerDiv {
    padding: 20px 0 0 0;
  }

  .works_timeine_centerDiv {
    margin: 16px 0 8px 0;
  }

  .works_title_p {
    width: 100% !important;
    font-size: 14px !important;
    line-height: 15px;
    /* border: 2px solid burlywood; */
  }
}



@media screen and (min-width: 861px) and (max-width: 1080px) {
  .works_title {
    font-size: 20px;
    margin-top: 0px;
    font-weight: 400;
    
  }

  .maps_p {
    font-size: 1rem;
  }

  .works_anroid_p {
    font-size: 16px;
  }

  .works_android_div {
    margin-left: 0;
  }

  .works_apps {
    margin-right: 5px;
    width: 90px;
  }

  .works_mobile_img {
    height: 31vh;
    margin-left: 0px;
  }

  .css-1ami7lv-MuiTimelineConnector-root {
    height: 31vh !important;
  }

  .works_mobile_div {
    margin-top: -16%;
  }

  .Works_centerDiv {
    padding: 20px 0 0 0;
  }

  .works_timeine_centerDiv {
    margin: 16px 0 8px 0;
  }

  .works_title_p {
    width: 50% !important;
    font-size: 15px !important;
    line-height: 20px;
  }
}



/* @media screen and (max-width: 660px) {
  
  .maps_p {
    font-size: 0.8rem;
  }
  .works_title {
    font-size: 16px;
    margin-top: 0px;
  }
} */
