.ReiluDriver_main {
  width: 100%;
}

.ReiluDriver_second {
  width: 90%;
  margin: auto;
  text-align: center;
}

.ReiluDriver_headings {
  padding: 3% 0;
}

.ReiluDriver_title {
  font-size: 40px;
  font-weight: 100;
}

.ReiluDriver_colmns_mein_div {
  display: flex;
  justify-content: space-between;
}

.ReiluDriver_col {
  width: 30%;
  text-align: left;
  display: flex;
  padding: 3% 2%;
}

.ReiluDriver_col_h2 {
  font-size: 24px;
  padding: 2% 0;
  font-weight: 500;
}

.ReiluDriver_col_p {
  color: #a7a7a7;
  font-size: 20px;
}

.ReiluDriver_video_div {
  padding: 3% 0;
}

.ReiluDriver_video_img {
  width: 100%;
}

@media screen and (min-width: 801px) and (max-width: 1100px) {
  .ReiluDriver_title {
    font-size: 30px;
  }

  .ReiluDriver_col_h2 {
    font-size: 22px;
  }

  .ReiluDriver_col_p {
    font-size: 15px;
  }
}

@media screen and (max-width: 800px) {
  .ReiluDriver_colmns_mein_div {
    flex-direction: column;
  }

  .ReiluDriver_col {
    width: 100%;
    margin: 6% 0;
  }

  .ReiluDriver_title {
    font-size: 35px;
  }
}

@media screen and (max-width: 400px) {
  .ReiluDriver_title {
    font-size: 19px;
    width: 60%;
    margin: auto;

  }

  .ReiluDriver_col_p {
    color: #a7a7a7;
    font-size: 15px !important;
  }
}