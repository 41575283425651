.Privacy_center{
    width: 80%;
    margin:0px auto;
    padding: 50px 0;
}
.Privacy_title{
    font-size: 30px;
    font-weight: bold;
    margin: 20px 0;
}
.Privacy_content_title{
    padding: 10px 0;
    font-size: 20px;
    font-weight: bold;
}
.Privacy_p{
    padding: 10px 0;
}