.Registration_main {
  width: 95%;
  margin: auto;
  padding: 30px 20px 20px 20px;
  overflow-x: scroll;
}

.Registration_main::-webkit-scrollbar {
  width: 0px;
}

.css-tlc64q-MuiPaper-root-MuiDialog-paper {
  max-width: 49% !important;
  margin: auto !important;
  border-radius: 20px !important;
}

.Registration_second {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.Registration_title {
  text-align: center;
  font-size: 33px;
  font-weight: 100;
  font-family: "Poppins";
  letter-spacing: 4px;
}

.Registration_colmn {
  width: 48%;
}

.Registration_label {
  display: block;
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  padding: 15px 0 10px 0;
}

.Registration_label2 {
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  margin: 10px;
  padding: 5px 0 10px 0;
}

.check_terms {
  margin: 22px 0 10px 0;
  width: 1rem;
  height: 1rem;
}

.Terms {
  color: #2973cc;
  text-decoration: none;
}

.Registration_input {
  width: 100%;
  padding: 5% 10px;
  background-color: #dfdfdf;
  border: 1px solid #dfdfdf;
  border-radius: 14px;
}

.gnder_inpt {
  margin: 0 8px 0 0;
  width: 1.2rem;
  height: 1.2rem;
}

.Registration_gnder {
  margin: 0 12% 0 0;
}

.Registration_btn {
  background: #2973cc;
  border-radius: 10px;
  color: #fff;
  border: none;
  padding: 2% 5%;
  margin-left: auto;
  display: block;
  font-size: 20px;
  font-weight: 500;
  margin-top: 6%;
  margin-bottom: 2%;
}

@media screen and (max-width: 560px) {
  .css-tlc64q-MuiPaper-root-MuiDialog-paper {
    max-width: 100% !important;
  }

  .Registration_title {
    font-size: 18px;
    font-weight: bold;
  }

  .Registration_second {
    flex-direction: column;
  }

  .Registration_colmn {
    width: 100%;
  }

  .Registration_label {
    font-size: 16px;
    padding: 10px 0 0px 0;
  }

  .Registration_label2 {
    font-size: 16px;
    /* margin: 0px;
    padding: 0 0 10px 0; */
  }
}

@media screen and (max-width: 860px) {
  .css-tlc64q-MuiPaper-root-MuiDialog-paper {
    max-width: 100% !important;
  }

  .Registration_title {
    font-size: 18px;
    font-weight: bold;
  }

  .Registration_label {
    font-size: 16px;
    padding: 15px 0 0px 0;
  }

  .Registration_label2 {
    font-size: 16px;
    /* margin: 0px; */
    /* padding: 0 0 10px 0; */
  }
}

@media screen and (max-width: 1260px) {
  .css-tlc64q-MuiPaper-root-MuiDialog-paper {
    max-width: 70% !important;
  }

}