.WhyReilu_main {
  margin: 0 0 5% 0;
}
.WhyReilu_second {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.WhyReilu_title {
  width: 90%;
  margin: 5% auto 50px auto !important;
  font-size: 40px;
  margin-bottom: 7% !important;
  font-weight: 100;
}

.WhyReilu_col {
  width: 35%;
  display: flex;
  flex-direction: column;
  padding: 1% 3% 0 0%;
  margin-top: -18%;
}

.WhyReilu_col2 {
  width: 65%;
}
.WhyReilu_col {
  padding: 0;
}

.WhyReilu_image_div {
  height: 122vh;
}
.ReiluDriver_col_img{
  margin: 0;
}
.WhyReilu_img {
  width: 100%;
  height: 100%;
}
.ReiluDriver_col_h2{
  /* margin: -10% 0%; */
}

.ReiluDriver_col_p_2 {
  color: #a7a7a7;
  font-size: 22px;
}

.Fairness_heading_2_2 {
  background-color: white;
  margin: 19% auto 0 auto;
  width: 42%;
  box-shadow: 0px 24px 77px rgb(0 0 0 / 8%);
  border-radius: 10px;
  transform: rotate(6.71deg);
  display: flex;
  padding: 2% 2%;
}

@media screen and (max-width: 800px) {
  .Fairness_heading_2_2 {
    width: 100%;
    transform: none;
  }

  .WhyReilu_second {
    flex-direction: column;
  }

  .WhyReilu_col_2 {
    width: 100%;
  }

  .WhyReilu_image_div {
    height: 50vh;
  }

  .HowCan_heading_2 {
    margin: 4% 0 0 19px;
  }

  .RestSection_center {
    height: 37vh;
  }

  .WhyReilu_main {
    margin: 29% 0 5% 0;
    text-align: center;
  }

  .RestSection_heading {
    margin: 0 0 -93px 0;
  }
}

@media screen and (max-width: 400px) {
  .RestSection_center {
    height: 33vh;
  }

  .RestSection_heading {
    margin: 0 0 -165px 0;
  }

  .RestSection_h1 {
    font-size: 18px;
    margin: 0 0 0% 0;
    padding: 6px 5px;
  }

  .WhyReilu_main {
    margin: 85% 0 0% 0;
    text-align: center;
  }

  .WhyReilu_title {
    font-size: 22px;
    margin-top: -30% !important;
  }

  .RestSection_box_title {
    line-height: 23px;
    font-size: 19px;
    padding: 2% 15%;
  }

  .RestSection_box_p {
    line-height: 17px;
    font-size: 13px;
    padding: 0px 9px;
    text-align: center;
    margin-top: -3%;
    color: #939393;
  }

  .RestSection_box_btn {
    font-weight: 500;
    padding: 6% 4%;
    color: #fff;
    border: none;
    width: 170%;
    height: 5vh;
    margin-left: -37%;
    border-radius: 10px;
  }

  .WhyReilu_col_2 {
    margin-top: 0%;
    padding: 0;
  }

  /* .ReiluDriver_col {
    height: 22vh;
  } */

  .ReiluDriver_col_p_2 {
    color: #a7a7a7;
    font-size: 15px;
  }
}
