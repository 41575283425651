.about_main {
  width: 100%;
}

.aboutHome_center_div {
  margin-top: 20px;
  width: 85%;
  margin: auto;
}
.aboutHome_h1 {
  font-size: 40px;
  font-family: 700;
  width: 70%;
}
.about_span {
  color: #8e8e93;
}
.aboutHome_p {
  margin-top: auto;
  font-size: 20px;
  font-weight: 400;
  padding-left: 10%;
}
.about_colmn {
  display: flex;
  align-items: end;
}
.aboutHome_bg_div {
  margin-top: 30px;
  margin-bottom: 30px;
}
.aboutHome_image {
  width: 100%;
}

.what_we_h1 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 50px;
  margin-top: 30px;
}
.what_we_box {
  background: #f6f6f6;
  border-radius: 30px;
  width: 418px;
  height: 295px;
  margin: 20px 0;
}
.what_we_h2 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 130px !important;
  line-height: 2rem;
}
.what_we_p {
  font-size: 20px;
  font-weight: 400;
  padding: 15px 0 10px 0;
}
.what_we_em {
  text-decoration-line: underline;
  font-size: 20px;
  font-weight: 700;
}
.what_we_em > a {
  color: #ffb000;
}
.what_we2 {
  margin-top: 70px;
  float: right;
}
.what_we_hr {
  border-bottom: 2px dashed #8e8e93;
  /* height: 0px; */
  display: flex;
  width: 100%;
  margin: 120px 0;
}
.Milestones_main {
  width: 100%;
  margin: auto;
}
.Milestones_second {
  background-image: url("../../assets//Image/LineBg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  margin: auto;
  height: 150vh;
  position: relative;
  background-size: cover;
  border-bottom: 2px dashed #8e8e93;
}
.Milestones_bg {
  width: 100%;
  height: 100%;
}
.Milestones_title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #000000;
  width: 85%;
  margin: auto;
}
.Milestones_heading {
  width: 100%;
}
.Milestones_card1 {
  width: 25%;
  margin: auto;
}
.Milestones_content {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #8e8e93;
}
.Milestones_h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 45px;
  color: #000000;
  margin-top: 18px;
  padding-bottom: 20px;
  width: 90%;
}
.Milestones_h3_2 {
  font-weight: 400;
  width: 20%;
  font-size: 40px;
}
.Milestones_h3_3 {
  font-size: 40px;
  font-weight: 400;
  width: 100%;
}
.Milestones_card_heading {
  padding-right: 50px;
  position: absolute;
  width: 25%;
  top: 7%;
  left: 35.5%;
}

.Milestones_colmn_content {
  width: 25%;
  position: absolute;
  top: 63%;
  left: 9%;
}
.Milestones_colmn_content2 {
  width: 25%;
  position: absolute;
  top: 48%;
  right: 7%;
  padding-right: 50px;
}
/* OurBrilliant  */

.OurBrilliant_second {
  width: 85%;
  margin: auto;
}
.OurBrilliant_heading {
  margin-top: 50px;
}
.ourBrilliant_h2 {
  font-weight: 400;
  font-size: 40px;
  line-height: 45px;
  color: #000000;
  width: 30%;
}
.ourBrilliant_p {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}
.ourCard {
  background: #f6f6f6;
  border: 1px solid #dfdfdf;
  border-radius: 14px;
  display: flex;
  align-items: center;
  margin-top: 100px;
}
.ourCard_col {
  width: 25%;
  text-align: center;
  padding: 18px 0;
}
.ourCard_col2 {
  width: 75%;
}
.ourCard_col_image_div {
  position: relative;
  margin: -90px 0 0 0;
}
.ourCard_heading {
  position: absolute;
  bottom: 3%;
  left: 37%;
}
.ourCard_title {
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
}
.ourCard_p {
  font-weight: 700;
  font-size: 16px;
}
.ourCard_content_p {
  font-weight: 400;
  font-size: 25px;
  line-height: 34px;
  padding-right: 20px;
}
/* AboutReilu   */
.AboutReilu_second {
  width: 85%;
  margin: 80px auto;
}
.AboutReilu_box {
  width: 650px;
  height: 584px;
  background: #f6f6f6;
  border-radius: 30px;
}
.AboutReilu_h1 {
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 20px;
}
.AboutReilu_p1 {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
}
.AboutReilu_p2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  margin: 30px 0;
}
