/* Header Component Style */
.navbar {
  border: blue;
}

.navbar_center_Div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: auto;
  padding: 1.5vw 0;
}

.nav_menu_div {
  display: flex;
  align-items: center;
}

.language {
  color: #ffb000;
  padding-right: 1vw;
}

.hamburger_div {
  padding-left: 1vw;
}

.navigation_manu {
  display: none;
}

.open_menu {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffb000;
  width: 100%;
  color: #fff;
  z-index: 1;
}

.nav_logo_close_btn_div {
  display: flex;
  justify-content: space-between;
}

.navigation_menu_center_div {
  width: 90%;
  margin: auto;
  padding: 25px 0;
}

.navigation_li {
  list-style: none;
  text-align: center;
  padding: 35px 0;
}

.navigation_a {
  text-decoration: none;
  color: #fff;
  display: block;
  font-weight: 600;
  font-size: 2.4rem;
  padding: 10px 0;
}

.menu_logo2 {
  width: 2rem;
  cursor: pointer;
}

.hmbgr_img {
  cursor: pointer;
  padding-right: 20px;
}

.navLogo {
  cursor: pointer;
  margin-right: 20px;
  width: 13rem;
}

.menu_logo {
  cursor: pointer;
}

.navLogo_responsive {
  visibility: visible;
  position: absolute;
  right: 40px;

}

.Hambrgr_responsive {
  visibility: hidden;
  display: none;
}

@media screen and (min-width: 0px) and (max-width: 360px) {
  .navbar {
    padding: 10px 0;
    background-color: #FFFFFF;
  }

  .navLogo {
    width: 5rem;
    margin: 0;
  }

  .hmbgr_img {
    width: 2rem;
    display: none;
  }

  .menu_logo {
    width: 4rem;
  }

  .menu_logo2 {
    width: 1.5rem;
    margin: -12px 0 0 0;
  }

  .navigation_a {
    font-size: 1.4rem;
    padding: 7px 0;
  }

  .navLogo2 {
    height: 22px;
  }

  .nav_menu_div {
    display: none;
  }

  .navLogo_responsive {
    visibility: visible;
    position: inherit;
  }

  .Hambrgr_responsive {
    visibility: visible;
    display: block;
  }

  .navLogoDiv {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

@media screen and (min-width: 361px) and (max-width: 800px) {
  .navbar {
    padding: 10px 0;
    background-color: #FFFFFF;
  }

  .navLogo {
    width: 7rem;
  }

  .hmbgr_img {
    width: 2rem;
    display: none;
  }

  .navLogo2 {
    height: 22px;
  }

  .nav_menu_div {
    display: none;
  }

  .navLogo_responsive {
    visibility: visible;
    position: inherit;

  }

  .Hambrgr_responsive {
    visibility: visible;
    display: block;
  }

  .navLogoDiv {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 7px 0;
  }
}