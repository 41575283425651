.DriversCard_main {
  width: 100%;
  /* border: 1px solid black; */
  margin-bottom: 5%;
  display: flex;
  justify-content: space-around;
  margin-top: 55px; 
  /* overflow: hidden;  */
  /* z-index: 20; */
}
.DriversCard_main1 {
  width: 100%;
  /* border: 1px solid black; */
  margin-bottom: 5%;
  display: flex;
  justify-content: space-around;
  /* margin-top: 55px;  */
  /* overflow: hidden;  */
  /* z-index: 20; */
}
.driver_link,.driver_link:hover{
  color: black;
  text-decoration: none;
}

.driver_cardChild{
  /* height: fit-content; */
  width: 31%; 
  border-radius: 8% !important;
  background-color: #F6F6F6 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  /* border: 1px solid black !important; */
}
.cardimg{
  width: 90%;
  margin-left: 5%;
}
/* .elishaaaaa{
  border: 3px solid red !important;
  border-radius: 25px;
  width: 33% !important;
  margin-top: 5%;
} */
.cardres{
  width: '22rem';
  border-radius: 25px !important;
  /* border-color: red; */
}
.cardres1{
  border-radius: 25px;
}
.DriversCircle{
  width: 110px;
  height: 110px;
  /* background: #000; */
  /* border: 2px solid rgb(246, 179, 33); */
  margin-top: -54px;
  margin-left: 10px;
  /* background-image: url("../../assets/Image/Cycle.png");
  background-repeat: no-repeat; */
  /* z-index: 20;
  overflow: hidden; */
  /* border-radius: 50px; */
  background-repeat: no-repeat;
}
.DriversCard_second {
  width: 90%;
  margin: auto;
  /* padding: 13px; */
  display: flex;
  justify-content:space-between;
}


.DriversCard_second1 {
  width: 90%;
  margin: auto;
  /* padding: 13px; */
  display: flex;
  justify-content:space-between;
}


.DriversCard_card {
/* border: 5px solid red; */
  width: 29.5%;
  background: #f6f6f6;
  height: 234px;
  border-radius: 30px; 
}
.DriversCard_card_image {
  width: 100%;
}
.DriversCard_card_title {
  font-size: 30px;
  font-weight: 300;
  margin-top: 8px;
}

.DriversCard_card_p {
  color: #a7a7a7;
  font-size: 18px;
  font-weight: 300;
  width: 90%;
  /* border: 1px solid black; */

}
.card_img{
  margin-top: 15px;
  width: 90%;
}
@media screen and (min-width:0px) and (max-width: 319px) {
  .headerCard_card_p{
    font-size:18px ;
    /* border: 3px solid yellow; */
  }
  }
  

@media screen and (min-width:320px) and (max-width: 400px) {
.headerCard_card_p{
  font-size:20px ;
}
}


@media screen and (min-width:401px) and (max-width: 799px) {
  .headerCard_card_p{
    font-size:20px ;
    line-height: 30px;
  }
  }

@media screen and (max-width: 800px) {
  .DriversCard_second {
    flex-direction: column;
    width: 100%;
    margin: auto;
    padding: 13px;
    display: flex;
    justify-content:space-around;
    /* display: flex;
    justify-content: space-evenly; */
  }
  .DriversCard_second1 {
    flex-direction: column;
    width: 100%;
    margin: auto;
    padding: 13px;
    display: flex;
    justify-content:space-around;
    /* display: flex;
    justify-content: space-evenly; */
  }
  .cardres1{
    margin-left: 3%;
  }



  .DriversCard_card {
    width: 100%;
    margin-bottom: 21%;
  }

  .DriversCard_card_title {
    font-size: 20px;
    font-weight: 100;
  }
  .DriversCard_card_p {
    font-size: 14px;
  }
  .driver_cardChild{
    width: 100%;
  }
  .cardres{
    width: 100% !important;
    /* border:50% !important; */
    /* border: 3px solid purple; */
  }
}




@media screen and (min-width: 801px)  and (max-width:900px){
  .DriversCard_second {
    /* flex-direction: column; */
    width: 100%;
    /* height: 100%; */
    margin-top: 15%;
    padding: 8px;
    display: flex;
    justify-content:space-between;
    /* display: flex;
    justify-content: space-evenly; */
  }
  .DriversCard_second1{
    width: 90%;
    display: flex;
    justify-content: space-around;
    /* border: 2px solid blue; */
  }
  
  .driver_cardChild{
    margin-top: 0 !important;
    width: 31.5% !important;
    /* height: 100%; */
  }
  .cardres{
    /* height: 33rem !important; */
    display: flex;
    /* margin: ; */
    height: 100% !important;
    justify-content: space-between;
    width: 100% !important;
    /* border: 1px solid yellow; */
  }
  .DriversCard_card {
    /* width: 100%; */
    /* margin-bottom: 21%; */
  }
  
  .DriversCard_card_title {
    font-size: 20px;
    font-weight: 100;
  }
  .DriversCard_card_p {
    font-size: 14px;
  }
  .driver_cardChild{
    width: 100%;
  }
  
  .headerCard_card_p{
    font-size: 14px;
    line-height: 22px;
  }
}




@media screen and (min-width: 901px)  and (max-width:1000px){
  .DriversCard_second {
    /* flex-direction: column; */
    width: 100%;
    /* height: 100%; */
    margin-top: 15%;
    padding: 8px;
    display: flex;
    justify-content:space-between;
    /* display: flex;
    justify-content: space-evenly; */
  }
  .DriversCard_second1{
    width: 90%;
    display: flex;
    justify-content: space-evenly;
    /* border: 2px solid blue; */
  }
  
  .driver_cardChild{
    margin-top: 0 !important;
    width: 31.5% !important;
  }
  .cardres{
    /* height: 33rem !important; */
    display: flex;
    /* margin: ; */
    justify-content: space-between;
    width: 100% !important;
    /* border: 1px solid yellow; */
  }
  .DriversCard_card {
    /* width: 100%; */
    /* margin-bottom: 21%; */
  }
  
  .DriversCard_card_title {
    font-size: 20px;
    font-weight: 100;
  }
  .DriversCard_card_p {
    font-size: 14px;
  }
  .driver_cardChild{
    width: 100%;
  }
  
  .headerCard_card_p{
    font-size: 15px;
    line-height: 23px;
  }
}




@media screen and (min-width: 1001px)  and (max-width:1100px){
  .DriversCard_second {
    /* flex-direction: column; */
    width: 100%;
    /* height: 100%; */
    margin-top: 15% !important;
    padding: 8px;
    display: flex;
    justify-content:space-between;
    /* display: flex;
    justify-content: space-evenly; */
  }
  .DriversCard_second1{
    width: 90%;
    display: flex;
    justify-content: space-between;
    /* border: 2px solid blue; */
  }
  
  .driver_cardChild{
    margin-top: 0 !important;
    width: 31.5% !important;
  }
  .cardres{
    /* height: 33rem !important; */
    display: flex;
    /* margin: ; */
    justify-content: space-between;
    width: 100% !important;
    /* border: 1px solid yellow; */
  }
  .DriversCard_card {
    /* width: 100%; */
    /* margin-bottom: 21%; */
  }
  
  .DriversCard_card_title {
    font-size: 20px;
    font-weight: 100;
  }
  .DriversCard_card_p {
    font-size: 14px;
  }
  .driver_cardChild{
    width: 100%;
  }
  
  .headerCard_card_p{
    font-size: 16px;
    line-height: 25px;
  }
}



@media screen and (min-width: 1101px)  and (max-width:1200px){
  .DriversCard_second {
    /* flex-direction: column; */
    width: 100%;
    /* height: 100%; */
    margin-top: 15%;
    padding: 8px;
    display: flex;
    justify-content:space-between;
    /* display: flex;
    justify-content: space-evenly; */
  }
  
  .DriversCard_second1{
    width: 90%;
    display: flex;
    justify-content: space-between;
    /* border: 2px solid blue; */
  }
  
  .driver_cardChild{
    margin-top: 0 !important;
    width: 31.5% !important;
  }
  .cardres{
    /* height: 33rem !important; */
    display: flex;
    /* margin: ; */
    justify-content: space-between;
    width: 100% !important;
    /* border: 1px solid yellow; */
  }
  .DriversCard_card {
    /* width: 100%; */
    /* margin-bottom: 21%; */
  }
  
  .DriversCard_card_title {
    font-size: 20px;
    font-weight: 100;
  }
  .DriversCard_card_p {
    font-size: 14px;
  }
  .driver_cardChild{
    width: 100%;
  }
  
  .headerCard_card_p{
    font-size: 18px;
    line-height: 28px;
  }
}

/* 
@media screen and (min-width: 1001px)  and (max-width:1100px){
  .DriversCard_second {
     flex-direction: column; 
    width: 100%;
     height: 100%; 
    margin-top: -7%;
    padding: 8px;
    display: flex;
    justify-content:space-between;
    display: flex;
    justify-content: space-evenly; 
  }
  
  
} */


