.css-1sjizby-MuiGrid-root > .MuiGrid-item {
  padding: 0 !important;
}
.blogs_center_div {
  margin: auto;
  width: 90vw !important;
}
.blogs_center_colmn {
  margin-bottom: 55px !important;
  display: flex;
  flex-direction: column !important;
  justify-content: end;
  max-width: 100% !important;
}
.blogs_home_h1 {
  font-size: 64px;
  font-weight: 100;
  padding: 0 0 12px 0;
}
.blogs_home_p {
  font-size: 20px;
  color: #a7a7a7;
}
.blogs_home_social_div {
  display: flex;
  align-items: center;
  margin: 5px 0 25px 0;
}
.blogs_home_social {
  width: 28px;
  height: 22px;
  margin-right: 10px;
}
.blogs_vlogs_div {
}
.blogs_all_vlogs {
  font-size: 24px;
  color: #ffb000;
  margin-right: 20px;
}
.blogs_fav_blogs {
  font-size: 24px;
  color: black;
  text-decoration: none;
}
.blogs_bg_div {
  width: 100%;
  height: 80vh;
}
.blogs_home_img {
  width: 612px;
  height: 100%;
  display: block;
  margin: auto;
}
.blogs_home_img2 {
  display: none;
}

@media screen and (min-width: 0px) and (max-width: 400px) {
  .css-11lq3yg-MuiGrid-root {
    flex-direction: column-reverse !important;
  }
  .blogs_home_img {
    display: none;
  }
  .blogs_home_img2 {
    display: block;
    width: 100%;

    height: 100%;
  }
  .blogs_center_colmn {
    margin-bottom: 13px !important;
  }
  .blogs_home_h1 {
    font-size: 19px;
    padding: 5px 0 7px 0;
  }
  .blogs_home_p {
    font-size: 14px;
    padding: 0 20px;
  }
  .blogs_contents {
    text-align: center;
  }
  .blogs_home_social_div {
    background-color: #f6f6f6;
    text-align: center;
    display: block;
    padding: 10px 0;
  }
  .blogs_all_vlogs {
    font-size: 19px;
    margin-right: 20px;
  }
  .blogs_fav_blogs {
    font-size: 19px;
  }
  .blogs_bg_div {
    width: 100%;
    height: 100%;
  }
  .css-1s50f5r {
    flex-basis: 100% !important;
  }
}
@media screen and (min-width: 401px) and (max-width: 600px) {
  .css-11lq3yg-MuiGrid-root {
    flex-direction: column-reverse !important;
  }
  .blogs_home_img {
    display: none;
  }
  .blogs_home_img2 {
    display: block;
    width: 100%;

    height: 100%;
  }
  .blogs_center_colmn {
    margin-bottom: 13px !important;
  }
  .css-1s50f5r {
    flex-basis: 100% !important;
  }
  .blogs_home_h1 {
    font-size: 19px;
  }
  .blogs_home_p {
    font-size: 14px;
    padding: 0 20px;
  }
  .blogs_contents {
    text-align: center;
  }
  .blogs_home_social_div {
    background-color: #f6f6f6;
    text-align: center;
    display: block;
    padding: 10px 0;
  }
  .blogs_all_vlogs {
    font-size: 19px;
    margin-right: 20px;
  }
  .blogs_fav_blogs {
    font-size: 19px;
  }
  .blogs_bg_div {
    width: 100%;
    height: 30vh;
  }
}
@media screen and (min-width: 601px) and (max-width: 900px) {
  .css-11lq3yg-MuiGrid-root {
    flex-direction: column-reverse !important;
  }
  .blogs_home_img {
    display: none;
  }
  .blogs_home_img2 {
    display: block;
    width: 100%;

    height: 100%;
  }
  .blogs_center_colmn {
    margin-bottom: 13px !important;
  }
  .css-1s50f5r {
    flex-basis: 100% !important;
  }
  .blogs_home_h1 {
    font-size: 19px;
  }
  .blogs_home_p {
    font-size: 14px;
    padding: 0 20px;
  }
  .blogs_contents {
    text-align: center;
  }
  .blogs_home_social_div {
    background-color: #f6f6f6;
    text-align: center;
    display: block;
    padding: 10px 0;
  }
  .blogs_all_vlogs {
    font-size: 19px;
    margin-right: 20px;
  }
  .blogs_fav_blogs {
    font-size: 19px;
  }
  .blogs_bg_div {
    width: 100%;
    height: 41vh;
  }
  .css-qwfiov-MuiGrid-root {
    flex-direction: column !important;
  }
  .css-wg4xta-MuiGrid-root {
    max-width: 80% !important;
    margin: auto !important;
  }
  .navLogo {
    margin-right: 20px;
    width: 9rem !important;
  }
  .food_title_h1 {
    font-size: 26px !important;
    padding: 20px 0;
  }
  .news_h1 {
    font-size: 25px !important;
    padding-bottom: 10px;
    color: #9c9c9c;
  }
  .can_colmn_p {
    font-size: 20px !important;
    padding: 14px 0;
  }
}
