.JoinUs_main_div {
  width: 100%;
  background: #f6f6f6;
}
/* body {
  background-color: #f6f6f6;
} */
.JoinUs_second_ {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  background: #ffb000;
  border-radius: 20px;
  color: #fff;
  align-items: center;
  padding: 3% 25px;
}
.JoinUs_title {
  font-weight: 800;
  font-size: 24px;
}
.JoinUs_p {
  font-size: 14px;
  font-weight: 500;
}
.JoinUs_input_div {
  width: 58%;
  display: flex;
}
.userName_input {
  border-radius: 10px;
  padding: 16px 20px;
  border: none;
  width: 47%;
  margin-right: 15px;
}
.userName_input2 {
  border-radius: 10px;
  padding: 16px 20px;
  border: none;
  width: 47%;
}
.JoinUs_headings {
  width: 20%;
}
.JoinUs_btn {
  padding: 8px 68px;
  color: #ffb000;
  font-size: 20px;
  font-weight: 500;
  border-radius: 10px;
  border: none;
  padding: 12px 68px;
  background-color: #fff;
}
@media screen and (min-width: 0px) and (max-width: 1200px) {
  .JoinUs_second_ {
    flex-direction: column;
  }
  .JoinUs_headings {
    width: 100%;
    text-align: center;
  }
  .JoinUs_input_div {
    width: 100%;
    flex-direction: column;
  }
  .userName_input {
    width: 100%;
    margin-bottom: 12px;
  }
  .userName_input2 {
    width: 100%;
    margin-bottom: 12px;
  }
  .JoinUs_btn {
    width: 100%;
    margin-top: 4px;
  }
  .JoinUse_btn_div {
    width: 100%;
  }
  .JoinUs_p {
    font-size: 14px;
    font-weight: 500;
    padding: 6px 0 17px 0;
  }
}
