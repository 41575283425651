body {
  height: 200vh;
}
.NewsSection_mainDiv {
  position: relative;
}
.NewsSection_secondDiv {
  width: 90%;
  margin: auto;
  background: url("../../assets/Image/NewsSectionBg.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 89vh;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.NewsSection_box {
  width: 90%;
  margin: 0 auto -50px auto;
  text-align: center;
  background-color: #f6f6f6;
  border-radius: 20px;
}
.NewsSection_center_box {
  width: 90%;
  margin: auto;
  padding: 20px 0;
}
.NewsSection_box_h2 {
  font-size: 40px;
  font-weight: 600;
}
.NewsSection_box_p {
  color: #a7a7a7;
  font-size: 20px;
  font-weight: 400;
}
.NewsSection_social {
  padding-top: 10px;
}
.NewsSection_social_img {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 15px;
}
