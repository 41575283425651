@media screen and (min-width: 60px) and (max-width: 600px) {
  .aboutHome_p {
    padding-left: 0;
    text-align: center;
    font-size: 16px;
  }
  .aboutHome_h1 {
    font-size: 20px;
    text-align: center;
    width: 100%;
  }
  .what_we_h1 {
    font-size: 20px;
    margin-bottom: 29px;
    margin-top: 8px;
  }
  .what_we2 {
    margin-top: 21px;
    width: 100%;
  }
  .what_we_h2 {
    font-size: 16px;
  }
  .what_we_p {
    font-size: 16px;
  }
  .what_we_em {
    font-size: 16px;
  }
  .Milestones_title,
  .Milestones_h3,
  .Milestones_h3_2,
  .Milestones_h3_3 {
    font-size: 20px;
    line-height: 29px;
  }
  .Milestones_second {
    padding-bottom: 50px;
    background-image: inherit;
    height: inherit;
  }
  .Milestones_content {
    font-size: 13px;
  }
  .Milestones_card_heading {
    width: 100%;
    position: inherit;
  }
  .Milestones_colmn_content {
    width: 85%;
    position: inherit;
    margin: auto;
  }
  .Milestones_h3_2 {
    width: 100%;
  }
  .Milestones_colmn_content2 {
    position: inherit;
    width: 85%;
    margin: auto;
    padding-right: 0;
  }
  .what_we_hr {
    margin: 35px 0;
  }
  .ourBrilliant_h2 {
    font-size: 20px;
    width: 100%;
  }
  .ourCard {
    margin-top: 73px;
    flex-direction: column;
  }
  .ourCard_man_img {
    width: 80%;
  }
  .Milestones_card1 {
    width: 85%;
    margin: auto;
  }
  .ourCard_content_p {
    font-size: 14px;
    line-height: 22px;
  }
  .ourCard_col {
    width: 63%;
    padding: 18px 0;
  }
  .ourCard_col_image_div {
    margin: -77px 0 0 0;
  }
  .ourCard_heading {
    bottom: 1%;
    left: 30%;
  }
  .ourCard_title,
  .AboutReilu_p1 {
    font-size: 16px;
  }
  .AboutReilu_h1 {
    font-size: 20px;
  }
  .AboutReilu_p2 {
    font-size: 16px;
    margin: 18px 0;
  }
  .AboutReilu_box {
    width: 100%;
    height: 300px;
  }
  .what_we_box {
    width: 100%;
  }
}
@media screen and (min-width: 601px) and (max-width: 900px) {
  .aboutHome_p {
    padding-left: 0;
    text-align: center;
  }
  .aboutHome_h1 {
    font-size: 30px;
    text-align: center;
    width: 100%;
  }
  .what_we_h1 {
    font-size: 30px;
    margin-bottom: 29px;
    margin-top: 8px;
  }
  .what_we2 {
    margin-top: 21px;
  }
  .what_we_h2 {
    font-size: 16px;
  }
  .what_we_p {
    font-size: 16px;
  }
  .what_we_em {
    font-size: 16px;
  }
  .Milestones_title {
    font-size: 30px;
  }
  .Milestones_second {
    padding-bottom: 50px;
    background-image: inherit;
    height: inherit;
  }
  .Milestones_h3 {
    font-size: 25px;
    line-height: 25px;
  }
  .Milestones_h3_2 {
    font-size: 25px;
    line-height: 25px;
  }
  .Milestones_h3_3 {
    font-size: 25px;
    line-height: 25px;
    margin-top: 20px;
  }
  .Milestones_content {
    font-size: 13px;
    line-height: 19px;
  }
  .Milestones_card_heading {
    width: 100%;
    position: inherit;
  }
  .Milestones_colmn_content {
    width: 85%;
    position: inherit;
    margin: auto;
  }
  .Milestones_h3_2 {
    width: 100%;
  }
  .Milestones_colmn_content2 {
    position: inherit;
    width: 85%;
    margin: auto;
    padding-right: 0;
  }
  .what_we_hr {
    margin: 35px 0;
  }
  .ourBrilliant_h2 {
    font-size: 30px;
    width: 100%;
  }
  .ourCard {
    margin-top: 73px;
  }
  .ourCard_man_img {
    width: 80%;
  }
  .Milestones_card1 {
    width: 85%;
    margin: auto;
  }
  .ourCard_content_p {
    font-size: 14px;
    line-height: 22px;
  }
  .ourCard_col {
    width: 40%;
    padding: 18px 0;
  }
  .ourCard_col_image_div {
    margin: -77px 0 0 0;
  }
  .ourCard_heading {
    bottom: 1%;
    left: 30%;
  }
  .ourCard_title {
    font-size: 16px;
    line-height: 22px;
  }
  .AboutReilu_h1 {
    font-size: 30px;
    line-height: 50px;
    margin-top: 20px;
  }
  .AboutReilu_p1 {
    font-size: 16px;
  }
  .AboutReilu_p2 {
    font-size: 16px;
    margin: 18px 0;
  }
  .AboutReilu_box {
    width: 80%;
    margin: auto;
    height: 484px;
    border-radius: 30px;
  }
}
@media screen and (min-width: 901px) and (max-width: 1400px) {
  .aboutHome_h1 {
    font-size: 30px;
    width: 100%;
  }
  .aboutHome_p {
    font-size: 18px;
  }
  .what_we_box {
    width: 100%;
  }
  .what_we_p {
    font-size: 16px;
    padding: 6px 0 10px 0;
  }
  .what_we_h1 {
    font-size: 30px;
    margin-bottom: 27px;
    margin-top: 0px;
  }
  .what_we_h2 {
    font-size: 18px;
  }
  .Milestones_title {
    font-size: 30px;
    line-height: 20px;
  }
  .Milestones_card_heading,
  .Milestones_colmn_content,
  .Milestones_colmn_content2 {
    padding-right: 0;
    width: 36%;
  }
  .Milestones_colmn_content2 {
    right: 0;
    padding-left: 30px;
  }
  .Milestones_colmn_content {
    top: 59%;
    left: 6%;
    padding-left: 30px;
  }
  .Milestones_h3 {
    font-size: 30px;
  }
  .Milestones_card_heading {
    top: 15%;
  }
  .ourBrilliant_h2 {
    font-size: 30px;
    width: 42%;
  }
  .ourCard_col {
    width: 40%;
  }
  .ourCard_heading {
    left: 31%;
  }
  .AboutReilu_box {
    width: 100%;
    height: 484px;
  }
  .AboutReilu_h1 {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 14px;
  }
  .AboutReilu_p1 {
    font-size: 16px;
  }
  .AboutReilu_p2 {
    font-size: 18px;
    margin: 12px 0;
  }
  .what_we2 {
    margin-top: 70px;
    width: 100%;
  }
  .Milestones_content {
    width: 80%;
  }
  .ourCard_content_p {
    font-size: 18px;
  }
}
