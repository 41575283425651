.Blogs2Section_main {
  width: 100%;
}
.Blogs2Section_second {
  width: 90%;
  margin: auto;
}
/* .Blogs2Section_heading {
  border: 1px solid rgb(255, 230, 0);
} */
.Blogs2Section_title {
  font-size: 40px;
  font-weight: 600;
}
.Blogs2Section_p {
  font-size: 20px;
  color: #a7a7a7;
  padding: 0px 0px 20px 0;
}
.Blogs2Section_img {
  width: 100%;
}
.Blogs2Section_by_name {
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  display: block;
}
/* responsive blogs2 section  */
@media screen and (min-width: 0px) and (max-width: 800px) {
  .Blogs2Section_title {
    font-size: 19px;
  }
  .Blogs2Section_p {
    display: none;
  }
  .Blogs2Section_image {
    display: flex;
    flex-direction: column-reverse;
  }
  .Blogs2Section_by_name {
    text-align: left;
    color: #a7a7a7;
    padding: 5px 0;
  }
}

/* ////////// Article /////////// */
.Article_second {
  width: 90%;
  margin: auto;
}
.Article_heading_colmns {
  display: flex;
}
.Article_colmn1 {
  width: 65%;
  background-color: #f6f6f6;
}
.Article_colmn2 {
  width: 35%;
}
.Article_colmn1_p {
  color: #1f1640;
  font-size: 20px;
  padding: 20px;
}
.Article_colmn2 {
  display: flex;
  /* justify-content: space-around; */
  height: 13vh;
  align-items: center;
  margin-left: 15px;
}
.Article_colmn2_social_div {
  background-color: #f6f6f6;
  padding: 18px 15px;
  border-radius: 20px;
  margin-right: auto;
}
.Article_share_like {
  display: flex;
}
.ArticleHeart {
  background-color: #f6f6f6;
  padding: 17px;
  margin-right: 9px;
  border-radius: 20px;

  width: 76px;
  height: 80px;
}
.ShareIcon {
  background-color: #f6f6f6;
  padding: 17px;
  margin-right: 9px;
  border-radius: 20px;

  width: 76px;
  height: 80px;
}
.main_footer_social3 {
  width: 61px;
  height: 30px;
  margin-right: 5px;
}
.main_footer_social4 {
  width: 61px;
  height: 30px;
}

/* responsive article colmns  */
@media screen and (min-width: 0px) and (max-width: 800px) {
  .Article_heading_colmns {
    flex-direction: column-reverse;
  }
  .Article_colmn2_social_div {
    display: flex;
    padding: 7px 11px;
  }
  .Article_colmn2 {
    width: 100%;
    margin-left: 0;
    height: 10vh;
  }
  .main_footer_social3 {
    width: 30px;
    height: 43px;
    margin-right: 9px;
  }
  .main_footer_social4 {
    width: 30px;
    height: 43px;
    margin-right: 9px;
  }
  .ArticleHeart {
    padding: 14px;
    width: 76px;
    height: 57px;
  }
  .ShareIcon {
    padding: 14px;
    width: 76px;
    height: 57px;
  }
  .Article_share_like {
    align-items: center;
  }
  .Article_colmn1 {
    width: 100%;
  }
  .Article_colmn1_p {
    font-size: 18px;
  }
}

/* /////////// Related_vlogs_colmn1 ////////// */
.Related_main_div {
  display: flex;
  justify-content: space-between;
}
.Related_vlogs_colmn1 {
  width: 65%;
}
.Related_vlogs_colmn2 {
  width: 34.5%;
  background: #f6f6f6;
  border-radius: 20px;
  margin: -60px 0 0 0;
  padding: 1% 1%;
}
.Related_vlogs_colmn1 {
  padding: 20px 0% 20px 0;
}
.Related_p1 {
  color: #a7a7a7;
  font-size: 18px;
  padding: 0px 1% 20px 0;
}
.Related_vlogs_title {
  font-size: 20px;
  font-weight: 400;
}
.Related_vlogs_heading_p {
  color: #a7a7a7;
  font-size: 18px;
  padding: 10px 1% 20px 0;
}
.Related_vlogs_images_div {
  display: flex;
  justify-content: space-between;
  margin-right: 15px;
}
.Related_last_p {
  color: #a7a7a7;
  font-size: 18px;
  padding: 10px 1% 20px 0;
}
.tag_div {
  padding: 5% 15px 0 0;
}
.tag_title {
  font-size: 40px;
  font-weight: 300;
  border-bottom: 0.5px solid #000000;
}
.Tages_type {
  font-size: 20px;
  font-weight: 400;
  padding: 3% 0;
}
.Related_card_title {
  padding: 4% 0 2% 0;
  font-size: 20px;
}
@media screen and (min-width: 0px) and (max-width: 800px) {
  .Related_vlogs_img {
    display: none;
  }
  .related2 {
    display: block;
    width: 100%;
  }
  .Related_main_div {
    flex-direction: column;
  }
  .Related_vlogs_colmn1 {
    width: 100%;
  }
  .Related_vlogs_colmn2 {
    width: 100%;
    margin: 21px 0 0 0;
  }
}
/* /////////// Comments component /////////// */
.Comments_main {
  width: 90%;
  margin: auto;
  padding: 3% 0%;
}
.Comments_second {
  width: 65%;
}
.Comments_title {
  font-size: 40px;
  border-bottom: 0.5px solid #000000;
}
.Comments_div {
  display: flex;
  margin: 2% 0;
  width: 90%;
}
.comments_profile {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.Comments_textarea {
  border: 0.5px solid #000000;
  border-radius: 10px;
  font-family: "Poppins";
  padding: 10px;
  width: 100%;
}
.Comments_profile_pic {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.user_comment_btn_div {
  width: 90%;
}
.Comments_post_btn {
  background-color: #ffb000;
  color: #fff;
  border-radius: 10px;
  padding: 10px 10px;
  font-size: 20px;
  border: none;
  display: block;
  margin-left: auto;
}
.user_Comments_div {
  width: 90%;
  /* border-top: 0.5px solid #000000; */
  display: flex;
  margin: 4% 0;
}
.Comments_user_p {
  font-size: 20px;
  font-weight: 400;
}
.Comments_span {
  font-size: 14px;
  font-style: italic;
  color: #9c9c9c;
  margin-left: 10px;
}
.Comments_user_Comments {
  font-size: 18px;
  color: #707070;
}
@media screen and (min-width: 0px) and (max-width: 800px) {
  .Comments_second {
    width: 100%;
  }
}
