.DriverSection_main {
  width: 100%;
}

.DriverSection_socond {
  width: 100%;
  display: flex;
  width: 90%;
  margin: auto;
}

.DriverSection_colmn {
  width: 20%;
  margin: auto;
}

.DriverSection_headigs {
  height: 70%;
  margin: 0 0 0 7px;
  z-index: 10;
  position: relative;
  width: 170%;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 20px;
  padding: 10% 20%;
}

.DriverSection_colmn2 {
  width: 80%;
}

.DriverSection_bg_img {
  width: 100%;

}

.DriverSection_title {
  font-size: 64px;
  font-weight: 300;
  line-height: 60px;
}

.DriverSection_title_p {
  font-size: 16px;
  padding: 5px 0;
}

.DriverSection_btn_top_text {
  text-align: center;
  padding: 7% 0 8px 0;
}

.DriverSection_btn {
  display: block;
  margin: auto;
  background-color:#FFB000;
  padding: 5% 25%;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

@media screen and (min-width: 801px) and (max-width: 1100px) {
  .DriverSection_title {
    font-size: 27px;
    line-height: 33px;
  }

  .DriverSection_title_p {
    font-size: 11px;
    padding: 5px 0;
  }

  .DriverSection_btn_top_text {
    padding: 3% 0 8px 0;
  }
}

@media screen and (min-width: 401px) and (max-width: 800px) {
  .DriverSection_colmn2 {
    width: 100%;
    position: relative;
  }

  .DriverSection_headigs {
    width: 80%;
    left: 8%;
    position: absolute;
    padding: 15px 17px;
    bottom: -18%;
    text-align: center;
    height: 45%;
  }

  .DriverSection_colmn {
    width: 0;
  }

  .DriverSection_title {
    font-size: 25px;
    font-weight: 400;
    line-height: 28px;
    padding-bottom: 10px;
  }

  .DriverSection_socond {
    margin-bottom: 25%;
  }

  .ReiluDriver_col {
    background-color: #f6f6f6;
    border-radius: 10px;
  }

  .ReiluDriver_col_img {
    margin: -32px 0 0 0;
  }

  .Fairness_heading_1_title {
    font-size: 22px !important;
    text-align: center;
  }

  .Fairness_heading_1_p {
    font-size: 16px;
    text-align: center;
  }

  .Fairness_heading_1_tag {
    text-align: center;
  }

  .Fairness_heading_1_button {
    margin: auto;
  }

  .Fairness_last_p {
    font-size: 17px;

  }
}

@media screen and (max-width: 400px) {
  .WhyReilu_second{
    padding-top: 0 !important;
  }
  .Fairness_socond {
    width: 100%;
    position: relative;
  }

  .DriverSection_socond {
    margin-bottom: 37%;
  }

  .DriverSection_colmn {
    width: 0;
  }

  .DriverSection_colmn2 {
    width: 100%;
  }

  .DriverSection_headigs {
    width: 85%;
    left: 6%;
    top: 33%;
    height: 50%;
    position: absolute;
    padding: 11px 15px;
    bottom: 15%;
    text-align: center;
    height: 36%;
    border: 2px solid white;
  }

  .DriverSection_title {
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
  }

  .DriverSection_title_p {
    font-size: 14px;
    color: #939393;
  }

  .DriverSection_btn_top_text {

    padding: 0% 0 8px 0;
    font-weight: 700;
  }

  .ReiluDriver_col {
    background-color: #f6f6f6;
    border-radius: 10px;
  }

  .ReiluDriver_col_img {
    margin: -32px 0 0 0;
  }

  .Fairness_heading_1_title {
    font-size: 22px !important;
    text-align: center;
  }

  .Fairness_heading_1_p {
    font-size: 15px !important;
    text-align: center;
  }

  .Fairness_heading_1_tag {
    text-align: center;
    font-weight: 700 !important;
  }

  .Fairness_heading_1_button {
    margin: auto;
  }

  .Fairness_last_p {
    font-size: 16px;
  }
}

@media screen and (min-width: 670px) and (max-width: 700px) {
  .DriverSection_headigs {
    width: 80%;
    left: 8%;
    position: absolute;
    padding: 15px 17px;
    bottom: -10%;
  }
}

@media screen and (min-width: 560px) and (max-width: 670px) {
  .DriverSection_headigs {
    width: 80%;
    left: 8%;
    position: absolute;
    padding: 15px 17px;
    bottom: 2%;

  }
}

@media screen and (min-width: 400px) and (max-width: 560px) {
  .DriverSection_socond {
    margin-bottom: 54%;
}
  .DriverSection_headigs {
    width: 80%;
    left: 8%;
    position: absolute;
    padding: 15px 17px;
    bottom: 10%;
  }
}