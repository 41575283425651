.can_download_div {
  background-color: #f6f6f6;
  /* margin-top: 100px; */
  width:100% ;
}

.can_download_centerDiv {
  /* width: 90%;
  margin: auto;
  display: flex;
  position: relative; */
}

.can_colmn {
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: 50%;

  /* margin-top: 200px; */
  height: 90vh;
}

.can_colmn2 {
  /* position: absolute; */
  /* width:70%; */
  /* right: 0% ; */
  /* z-index: 1; */
  /* height: 300px; */
  /* border: 2px solid purple; */
  /* position: relative; */
}

.colmn2_mbl_div {
  position: relative;
  height: 100%;
  /* right: 1%; */
  /* height: 100%!important; */

}
.can_colmn2_img {
  /* position: absolute;
  border-style: none;
  border: 3px solid green;
  width: 100%;
  height: 100%; */
}
.can_colmn_h2 {
  font-size: 40px;
  font-weight: 300;
}

.can_colmn_p {
  color: #a7a7a7;
  font-size: 16px;
  font-weight: 400;
  padding: 1vw 8vw 1vw 0;
}

.work_download_app_div2 {
  display: flex;
}

.works_apps2 {
  width: 8rem;
  margin-right: 10px;
}

.whatapp_scaner {
  padding: 1vw 0;
  width: 20%;
}

/* main footer  */
.main_footer {
  width: 90%;
  margin: auto;
}

.main_footer_centerDiv {
  display: flex;
  padding: 3vw 0;
}

.main_footer_colms {
  width: 25%;
}

.main_footer_h2 {
  font-size: 24px;
  font-weight: 600;
  padding: 8px 0;
}

.main_footer_a {
  display: flex;
  text-decoration: none;
  font-size: 20px;
  font-weight: 400;
  color: black;
  padding: 5px 0;
}

.main_footer_pera {
  font-size: 14px;
  font-weight: 400;
  color: #a7a7a7;
  padding: 1vw 0;
}

.main_footer_input_div {
  position: relative !important;
}

.main_footer_input {
  width: 100%;
  border: none;
  padding: 14px;
  border: 1px solid #000000;
  border-radius: 10px;
  position: relative !important;
}

.main_footer_btn {
  position: absolute !important;
  background-color: black;
  color: #fff;
  right: 7px;
  top: 13%;
  padding: 6px 23px;
  border-radius: 10px;
}

.main_footer_social_div {
  padding: 1vw 0;
}

.main_footer_social {
  width: 34px;
  height: 34px;
  margin-right: 16px;
}

.main_footer_icons {
  margin-right: 10px;
}

.down_footer {
  border-top: 0.5px solid #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vw 5vw;
}

.down_footer_heading {
  display: flex;
}

.down_footer_p {
  font-size: 14px;
  font-weight: 400;
  
}

.down_footer_p2 {
  margin-right: 20px;
  font-size: 14px;
  font-weight: 400;
  color: black;
  text-decoration: none;
}
.phoneimg{
  display: none;
}
.down_footer_p3 {
  padding-right: 5vw;
  font-size: 14px;
  font-weight: 400;
  color: black;
  text-decoration: none;
}

.can_responsive_img {
  display: none;
}

.footer_logo {
  cursor: pointer;
  width: 13rem;
}

 @media screen and (min-width: 0px) and (max-width: 260px) {
  .can_colmn_h2 {
    font-size: 22px;
    font-weight: 300;
  }

  .can_colmn_p {
    font-size: 12px;
    padding: 14px 0;
  }

  .whatapp_scaner {
    padding: 4vw 0;
    width: 8rem;
  }

  .can_download_centerDiv {
    flex-direction: column;
  }

  .main_footer_centerDiv {
    flex-direction: column;
  }

  .down_footer {
    flex-direction: column;
    text-align: left;
    display: block;
  }

  .can_colmn {
    /* flex-direction: column; */
    width: 100%;
    height: initial;
    text-align: center;
    padding: 18px 0 0 0;
  }

  .can_colmn2 {
    width: 100%;
    text-align: center;
  }

  /* .colmn2_mbl_div {
    position: initial;
    display: none;
  } */

  .main_footer_colms {
    width: 100%;
    margin: 15px 0;
  }

 

  .works_apps2 {
    width: 6rem !important;
    margin-right: 4px;
    margin-bottom: 15px;
  }

  .footer_logo {
    width: 6rem;
    float: left;
  }

  .main_footer {
    text-align: center;
  }

  .main_footer_a {
    font-size: 14px;
    align-items: center;
    display: flex;
  }

  .main_footer_h2 {
    font-size: 18px;
    text-align: left;
  }

  .main_footer_icons {
    width: 1rem;
  }

  .main_footer_pera {
    font-size: 12px;
    padding: 0px 0 21px 0;
    text-align: left;
  }

  .main_footer_social_div {
    padding: 19px 0 0 0;
  }

  .main_footer_social {
    width: 27px;
    height: 19px;
    margin-right: 8px;
  }

  .down_footer_p {
    font-size: 9px;
  }

  .down_footer_p2 {
    font-size: 9px;
  }

  .down_footer_p3 {
    font-size: 9px;
  }

  .down_footer_heading {
    text-align: left;
  }

  .work_download_app_div2 {
    display: flex;
    flex-direction: column;
  }

  .main_footer_btn {
    position: initial;

    margin: 0 0 0 -78px;
  }

  .main_footer_input {
    width: 100%;
  }

  .can_responsive_img {
    display: block;
    width: 100%;
  }

  .can_respnsive_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (min-width: 261px) and (max-width: 560px) {
  .can_download_centerDiv{
    display: flex;
    align-items: center;
  }
  .can_colmn{
    width: 100% !important;
    
  }
  
   .can_colmn_h2 {
    font-size: 19px;
    font-weight: 300;
  }
  
  .css-12iw1sl {
    height: 228px !important;
  }

  .can_colmn_p {
    font-size: 14px;
    padding: 14px 0;
  }

  .can_download_centerDiv {
    flex-direction: column;
  }

  .main_footer_centerDiv {
    flex-direction: column;
  }

  .down_footer {
    flex-direction: column;
    text-align: left;
    display: block;
  }

  .can_colmn {
    /* flex-direction: column; */
    width: 100%;
    height: initial;
    text-align: center;
    padding: 18px 0 0 0;
  }

  .can_colmn2 {
    width: 100%;
    margin-top: 150px;
    text-align: center;
  }

  /* .colmn2_mbl_div {
    position: initial;
    display: none;
  } */

  .main_footer_colms {
    width: 100%;
    margin: 15px 0;
  }

  /* .can_colmn2_img {
    width: 25rem;
    border: 1px solid bl ack;
  } */

  .works_apps2 {
    width: 6rem !important;
    margin-right: 4px;
    margin-bottom: 15px;
  }

  .footer_logo {
    width: 8rem;
    float: left;
  }

  .main_footer {
    text-align: center;
  }

  .main_footer_a {
    font-size: 14px;
    align-items: center;
    display: flex;
  }

  .main_footer_h2 {
    font-size: 18px;
    text-align: left;
  }

  .main_footer_icons {
    width: 1rem;
  }

  .main_footer_pera {
    font-size: 12px;
    padding: 0px 0 21px 0;
    text-align: left;
  }

  .main_footer_social_div {
    padding: 19px 0 0 0;
  }

  .main_footer_social {
    width: 27px;
    height: 19px;
    margin-right: 8px;
  }

  .down_footer_p {
    font-size: 12px;
  }

  .down_footer_p2 {
    font-size: 12px;
  }

  .down_footer_p3 {
    font-size: 12px;
  }

  .down_footer_heading {
    text-align: left;
  }

  .work_download_app_div2 {
    display: flex;
    flex-direction: column;
  }

  .main_footer_btn {
    position: initial;

    margin: 0 0 0 -78px;
  }

  .main_footer_input {
    width: 100%;
  }

  .can_responsive_img {
    display: block;
    width: 100%;
  }

  .can_respnsive_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .can_colmn_p {
    font-size: 14px;
    padding: 14px 40px;
  }

  .whatapp_scaner {
    padding: 4vw 0;
    width: 6rem;
  }

  .can_download_centerDiv {
    flex-direction: column;
  }

  .main_footer_centerDiv {
    flex-direction: column;
  }

  .down_footer {
    flex-direction: column;
    text-align: left;
    display: block;
  }

  .can_colmn {
    flex-direction: column;
    width: 100%;
    height: initial;
    text-align: center;
    padding: 18px 0 0 0;
  }


  .main_footer_colms {
    width: 100%;
    margin: 15px 0;
  }

  

  .works_apps2 {
    width: 6rem !important;
    margin-right: 4px;
    margin-bottom: 15px;
  }

  .footer_logo {
    width: 8rem;
    float: left;
  }

  .main_footer {
    text-align: center;
  }

  .main_footer_a {
    font-size: 14px;
    align-items: center;
    display: flex;
  }

  .main_footer_h2 {
    font-size: 18px;
    text-align: left;
  }

  .main_footer_icons {
    width: 1rem;
  }

  .main_footer_pera {
    font-size: 12px;
    padding: 0px 0 21px 0;
    text-align: left;
  }

  .main_footer_social_div {
    padding: 19px 0 0 0;
  }

  .main_footer_social {
    width: 27px;
    height: 19px;
    margin-right: 8px;
  }

  .down_footer_p {
    font-size: 9px;
  }

  .down_footer_p2 {
    font-size: 9px;
  }

  .down_footer_p3 {
    font-size: 9px;
  }

  .down_footer_heading {
    text-align: left;
  }

  .work_download_app_div2 {
    display: flex;
    flex-direction: column;
  }

  .main_footer_input {
    width: 100%;
  }

  .can_responsive_img {
    display: block;
    width: 100%;
  }

  .can_respnsive_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
  }

  .navLogo {
    cursor: pointer;
    width: 10rem;
  }
  .blogs_vlogs_div {
    margin: auto;
  }
}

@media screen and (min-width: 561px) and (max-width: 860px) {
  .can_colmn_h2 {
    font-size: 22px;
    font-weight: 300;
  }
  .css-12iw1sl {
    height: 237px !important;
  }
  .navLogo {
    cursor: pointer;
    width: 10rem;
  }
  .can_colmn_p {
    font-size: 12px;
    padding: 14px 50px;
  }

  .whatapp_scaner {
    padding: 4vw 0;
    width: 8rem;
  }

  .can_download_centerDiv {
    flex-direction: column;
  }

  .main_footer_centerDiv {
    flex-direction: column;
  }

  .main_footer_btn {
    background-color: black;
    color: #fff;
    right: 26% !important;
    top: 15% !important;
    padding: 6px 20px;
    border-radius: 10px;
    position: absolute !important;
  }

  .down_footer {
    flex-direction: column;
    text-align: left;
    display: block;
  }

  .can_colmn {
    flex-direction: column;
    width: 100%;
    height: initial;
    text-align: center;
    padding: 18px 0 0 0;
  }

  
  .main_footer_colms {
    width: 100%;
    margin: 15px 0;
  }


  .works_apps2 {
    width: 8rem !important;
    margin-right: 4px;
    margin-bottom: 15px;
  }

  .footer_logo {
    width: 12rem;
    float: left;
  }

  .main_footer {
    text-align: center;
  }

  .main_footer_a {
    font-size: 16px;
    align-items: center;
    display: flex;
  }

  .main_footer_h2 {
    font-size: 18px;
    text-align: left;
  }

  .main_footer_icons {
    width: 1rem;
  }

  .main_footer_pera {
    font-size: 14px;
    padding: 0px 0 21px 0;
    text-align: left;
  }

  .main_footer_social_div {
    padding: 19px 0 0 0;
  }

  .main_footer_social {
    width: 27px;
    height: 19px;
    margin-right: 8px;
  }

  .down_footer_p {
    font-size: 9px;
  }

  .down_footer_p2 {
    font-size: 9px;
  }

  .down_footer_p3 {
    font-size: 9px;
  }

  .down_footer_heading {
    text-align: left;
  }

  .work_download_app_div2 {
    display: flex;
    flex-direction: column;
  }

  .main_footer_btn {
    position: initial;

    margin: 0 0 0 -78px;
  }

  .main_footer_input {
    width: 50%;
  }

  .can_responsive_img {
    display: block;
    width: 100%;
  }

  .can_respnsive_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;

  }
}

@media screen and (min-width: 861px) and (max-width: 1260px) {

  .can_colmn_h2 {
    font-size: 27px;
  }

  .can_colmn_p {
    font-size: 17px;
    padding: 2vw 8vw 2vw 0;
  }

  
  .whatapp_scaner {
    padding: 2vw 0;
  }

  .main_footer_h2 {
    font-size: 18px;
  }

  .main_footer_a {
    font-size: 11px;
    align-items: center;
  }

  .main_footer_icons {
    width: 1rem;
  }

  .main_footer_social {
    width: 1.5rem;
  }

  .main_footer_btn {
    font-size: 11px;
  }
}

@media screen and (min-width: 1261px) and (max-width: 1390px) {

  .can_colmn_h2 {
    font-size: 44px;
  }

  .can_colmn_p {
    font-size: 17px;
    padding: 2vw 8vw 2vw 0;
  }


  .whatapp_scaner {
    padding: 2vw 0;
  }

  .main_footer_h2 {
    font-size: 18px;
  }

  .main_footer_a {
    font-size: 11px;
    align-items: center;
  }

  .main_footer_icons {
    width: 1rem;
  }

  .main_footer_social {
    width: 1.5rem;
  }

  .main_footer_btn {
    font-size: 11px;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1800px) {
  .can_colmn_h2 {
    font-size: 80px !important;
  }
}

@media screen and (min-width: 1801px) and (max-width: 2300px) {
  .can_colmn_h2 {
    font-size: 70px !important;
    font-weight: 100 !important;
  }

  /* .colmn2_mbl_div {
    right: 12%;
    top: 95px;
  } */
} 
/* 
@media screen and (max-width:760px) {
  .iphone_div{
position: absolute;
top: 0;
margin-top: -40%;
height: 60vh !important;
width: 90% !important;
margin-left: 5%;
border: 3px solid black !important;
  }
} */