/* body {
  background-color: #f6f6f6;
} */

.Trending_maindiv {
  margin: 7% 0 0 0;
  background: #f6f6f6;
}
.Trending_secondDiv {
  width: 90%;
  margin: auto;
}
.Trending_title {
  font-size: 40px;
  font-size: 100;
  border-bottom: 1px solid #a7a7a7;
  margin-bottom: 10px;
  width: 99%;
  margin-left: auto;
  margin-right: auto;
}
.Trending_box {
  display: flex;
}
.Trending_colmns {
  width: 50%;
}
.Trending_colmns2 {
  width: 50%;
}
.Trending_row2 {
  display: flex;
}
.Trending_row_colmn2 {
  width: 50%;
}
.Trending_colmns {
  background: url("../../assets//Image/NewsImg1.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 98vh;
  margin: 7px;
  display: flex;
}
.Trending_row_colmn {
  background: url("../../assets//Image//NewsImg2.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 44vh;
  margin: 7px;
  display: flex;
}
.Trending_row_colmn2 {
  background: url("../../assets//Image//NewsImg3.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 51vh;
  width: 100%;
  margin: 7px;
  display: flex;
}
.Trending_row_colmn3 {
  background: url("../../assets//Image//NewsImg4.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 51vh;
  width: 100%;
  margin: 7px;
  display: flex;
}
.Trending_headings {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  padding: 0 20px !important;
}
.card_title22 {
  color: #fff;
  font-size: 12px;
}

@media screen and (min-width: 0px) and (max-width: 1200px) {
  .NewsSection_secondDiv {
    height: 35vh;
  }
  .NewsSection_box_p {
    font-size: 14px;
  }
  .NewsSection_box_h2 {
    font-size: 22px;
    font-weight: 600;
  }
  .NewsSection_box {
    width: 100%;
  }
  .Trending_maindiv {
    margin: 20% 0 0 0;
  }
  .Trending_box {
    flex-direction: column;
  }
  .Trending_colmns {
    height: 25vh;
    margin: 7px;
    width: 100%;
  }
  .Trending_colmns2 {
    width: 100%;
    display: flex;
  }
  .Trending_row2 {
    flex-direction: column;
    width: 45%;
  }
  .Trending_row {
    width: 55%;
  }
  .Trending_row_colmn {
    height: 30vh;
    display: block;
  }
  .Trending_row_colmn2 {
    height: 14vh;
    display: block;
  }
  .Trending_row_colmn3 {
    height: 14vh;
    display: block;
  }
  .card_title {
    font-size: 19px;
  }
  .card_title_p2 {
    font-size: 14px;
  }
}
