.Fairness_main {
  padding: 6% 0 0 0;
}

.Fairness_socond {
  width: 78vw;
  margin: auto;
  background: url("../../assets/Image/FairnessBg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.SECOND_IMG2 {
  display: none;
}

.Hear_Div {
  margin-top: 10%;
  /* border: 1px solid black; */
}

.Fairness_heading_1 {
  width: 39%;
  margin: 0% -8%;
}

.Fairness_heading_1_title {
  font-size: 40px;
  font-weight: 100;
  line-height: 45px;
}

.Fairness_heading_1_p {
  font-size: 18px;
  color: #8e8e93;
  padding: 1% 0;
  /* border:1px solid black; */
}

.Fairness_heading_1_tag {
  font-size: 18px;
  font-weight: 400;
  padding: 2% 0;
}

.Fairness_heading_1_button {
  background: #2973cc;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 500;
  /* padding-top: 3%; */
  border: none;
  height: 50px !important;
  width: 90%;
  box-sizing: border-box;
  color: #fff;
  display: block;
}

.Fairness_heading_2 {
  background-color: white;
  margin: 0% auto 0 auto;
  width: 40%;
  box-shadow: 0px 24px 77px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  transform: rotate(6.71deg);
  display: flex;
  padding: 2% 2%;
}

.Fairness_heading_3 {
  background-color: white;
  margin: 12% auto 0 17%;
  width: 74%;
  box-shadow: 0px 24px 77px rgb(0 0 0 / 8%);
  border-radius: 10px;
  display: flex;
  padding: 6% 2%;
  transform: rotate(6.71deg);
}

.Fairness_heading_4 {
  background-color: white;
  margin: -12% auto 0 69px;
  width: 75%;
  box-shadow: 0px 24px 77px rgb(0 0 0 / 8%);
  border-radius: 10px;
  display: flex;
  padding: 6% 2%;
  /* border: 3px solid blue; */

  transform: rotate(-10.71deg);
}

.Fairness_heading_5 {
  background-color: white;
  margin: -2% 17% 0 auto;
  width: 39%;
  box-shadow: 0px 24px 77px rgb(0 0 0 / 8%);
  border-radius: 10px;
  transform: rotate(-7.24deg);
  display: flex;
  padding: 3% 2%;
  position: relative;
  top: -39px;
}

.Fairness_heading_2_col {
  width: 30%;
}

.Fairness_heading2 {
  font-size: 3rem !important;
  width: 70%;
  line-height: 50px !important;
  font-size: 2rem;
  /* padding-left: 10%; */
  line-height: 35px;
  padding-bottom: 10px;
}
.Hear_Div  {
  width: 90%;
  margin:50px auto;
}

.Fairness_p2 {
  width: 80%;
  font-size: 1.3rem !important;
}

.Fairness_heading_2_title {
  font-size: 18px;
  font-weight: 700;
}

.Fairness_heading_2_p {
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  padding: 4% 15% 0 0;
}

/* heading 3  */
.Fairness_heading_3_main {
  margin: 15% 0;
}

.Fairness_heading_3_socond {
  display: flex;
}

.Fairness_heading_3_colmn {
  width: 50%;
}
.Fairness_heading_3_colmn2 {
width: 50%;
}

.Fairness_heading_3_colmn22 {
  width: 50%;
  /* border: 2px solid black; */
  display: flex;
  align-items: center;

}

.Fairness_heading_3_col {
  width: 20%;
}

.Fairness_socond2 {
  width: 60%;
  text-align: center;
  margin: auto;
}

.Fairness_last_heading {
  padding: 7% 0px;
}

.Fairness_last_title {
  font-size: 50px;
  font-weight: 400;
}

.Fairness_last_p {
  color: #8e8e93;
  font-size: 18px;
  width: 70%;
  margin-left: 15%;
}

.Fairness_heading_4_col2 p{
font-size:18px ;
}
/* .Fairness_p2 {
  font-size: 0.8rem;
  color: #8e8e93;
  padding: 3% 10%;
} */
@media screen and (min-width: 901px) and (max-width: 1400px){
.Fairness_heading2 {
  font-size: 2rem !important;
}
}
@media screen and (min-width: 1600px) {
  .Fairness_socond {
    width: 61vw;
    height: 139vh;
  }
}

@media screen and (min-width: 801px) and (max-width: 1100px) {
  .Fairness_socond {
    width: 82%;
    background-size: contain;
  }

  .Fairness_heading_1_title {
    font-size: 32px;
  }

  .Fairness_heading_1_p {
    font-size: 13px;
  }

  .Fairness_heading_1_tag {
    font-size: 15px;
  }

  .Fairness_heading_1_button {
    font-size: 15px;
  }

  .Fairness_heading_2_title {
    font-size: 16px;
  }

  .Fairness_heading_2_p {
    font-size: 12px;
    line-height: 17px;
    padding: 4% 0% 0 0;
  }

  .Fairness_heading_2_col {
    width: 52%;
    text-align: center;
  }

  .Fairness_heading_3_col {
    width: 26%;
  }

  .Fairness_last_title {
    font-size: 32px;
  }

  .HowCan_p {
    font-size: 14px;
    padding: 1% 0 3% 0;
  }

  .HowCan_h1 {
    font-size: 26px;
  }

  .HowCan_social_img {
    margin-right: 8px;
    width: 6rem;
  }

  .Fairness_heading_2 {
    margin: -9% auto 0 auto;
  }
}

@media screen and (max-width: 800px) {
  .Fairness_heading_1 {
    width: 100%;
    margin: 0;
  }

  .Fairness_heading_2 {
    width: 100%;
    margin: 30px auto 0 auto;
    transform: none;
    padding: 6% 0;
  }
  .Fairness_heading_3_main{
    margin: 0;
  }

  .Fairness_heading_3_socond {
    width: 100%;
    margin: 30px 0 0 0;
    padding: 0;
    transform: none;
    flex-direction: column;
  }

  .Fairness_heading_3_colmn {
    width: 100%;
  }

  .Fairness_heading_3_colmn2 {
    width: 100%;
  }

  .Fairness_heading_5 {
    width: 100%;
    margin: 22% auto 0 auto;
    transform: none;
    padding: 6% 2%;
  }

  .Fairness_heading_1_title {
    font-size: 35px;
    transform: none;
  }

  .Fairness_heading_3 {
    width: 100%;
    margin: 22% auto 0 auto;
    transform: none;
    padding: 6% 2%;
  }

  .Fairness_heading_4 {
    width: 100%;
    margin: 22% auto 0 auto;
    transform: none;
    padding: 6% 2%;
  }

  .Fairness_socond {
    width: 90%;
  }
  .Fairness_socond2 {
    width: 90%;
  }

  .Fairness_last_title {
    font-size: 22px;
  }
}

@media screen and (max-width: 880px) {
  .Fairness_heading_1_title {
    font-size: 35px;
  }


  .Fairness_socond2 {
    width: 90%;
  }

  .Fairness_last_title {
    font-size: 22px;
  }

  /* .Fairness_heading_4_col_img {
    width: 90% !important;
  } */

  .Fairness_heading_2_col {
    margin-top: 3%;
    text-align: center;
  }

  .Fairness_heading_4_col {
    margin-top: 3%;
    width: 30%;
    text-align: center;
  }
  .Fairness_heading_3_col{
    margin-top: 3%;
    width: 30%;
    text-align: center;
  }

  .Fairness_heading_2_p {
    font-size: 13px;
    width: 100%;
    margin-top: -5%;
    padding: 4% 4% 0 0;
  }

  .Fairness_heading_3_p {
    font-size: 13px;
    width: 100%;
  }

  .Fairness_heading_2_col2 {
    width: 100%;
    padding: 5% 0%;
  }

  .Fairness_heading_3_col2 {
    width: 100%;
    padding: 3% 0%;
  }

  .Fairness_heading_4_col2 {
    width: 100%;
    padding: 3% 0%;
  }

  .Fairness_heading_5_col2 {
    width: 100%;
    padding: 3% 0%;
  }

  .Fairness_socond {
    background-image: none;
  }

  .Fairness_heading_2_2 {
    width: 100% !important;
    min-height: 15vh;
    margin: 0 auto 0 auto;
    transform: none;
    padding: 6% 0;
  }

  .Fairness_heading_3 {
    width: 100%;
    min-height: 15vh;
    margin: 0 auto 0 auto;
    transform: none;
    padding: 6% 0;
  }
  .Fairness_heading_3_colmn22{
    width: 100%;
  }

  .Fairness_heading_4 {
    width: 100%;
    min-height: 15vh;
    margin: 30px auto;
    transform: none;
    padding: 6% 0;
  }

  .Fairness_heading_5 {
    width: 100%;
    min-height: 15vh;
    margin: 40px auto 0 auto;
    transform: none;
    padding: 6% 2%;
  }

  .Fairness_heading_2_title {
    font-size: 17px;
    font-weight: 500;
    font-weight: 700;
  }

  .Fairness_heading2 {
    width: 100%;
    font-size: 1.3rem !important;
    line-height: 35px !important;
    text-align: center !important;
    font-weight: 400 !important;
  }

  .Fairness_p2 {
    width: 100% !important;
    font-size: 0.8rem !important;
    color: #8e8e93 !important;
    padding: 3% 5% !important;
    text-align: center;
  }

  .Fairness_heading_1_button {
    background: #2973cc;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 500;
    /* padding: 0 31%; */
    border: none;
    color: #fff;
    display: block;
  }

  .Fairness_last_p {
    color: #8e8e93;
    font-size: 15px;
    width: 101%;
    margin-left: -1%;
  }

  .HowCan_main_2 {
    display: none;
  }

  .SECOND_IMG2 {
    width: 100%;
    height: 45vh;
    position: relative;
    display: block;
  }

  .bluee {
    /* padding-top: 5%; */
    position: absolute;
    left: 4%;
    top: 5% !important;
    width: 90%;
  }

  .Restaurant {
    position: absolute;
    left: 10%;
    top: 15% !important;
    /* padding-top: 10%; */
  }

  .Our_restaurant {
    position: absolute;
    left: 10%;
    top: 30% !important;
    /* padding-top: 25%; */
    width: 77%;
  }
}
