.HowCan_main {
  width: 100%;
  margin-top: 5%;
}

.HowCan_second {
  width: 90%;
  margin: auto;
  background: url("../../assets/Image/HowCanBg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 37vw;
  display: flex;
  align-items: center;
  color: #fff;
}

.HowCan_heading {
  width: 69%;
  padding: 0 11px 0 4%;
  margin: -6% 0 0 0;
}

.HowCan_h1 {
  font-size: 36px;
  font-weight: 700;
}

.HowCan_p {
  font-size: 18px;
  padding: 1% 0 6% 0;
}

.HowCan_social_img {
  margin-right: 15px;
}

.SECOND_IMG {
  display: none;
}
.SECOND_IMG2 {
  display: none;
}

@media screen and (max-width: 800px) {
  .HowCan_h1 {
    font-size: 12px;
    font-weight: 300;
  }

  .HowCan_p {
    font-size: 7px;
    padding: 1% 0 3% 0;
  }

  .HowCan_social_img {
    margin-right: 8px;
    width: 3rem;
  }
}

@media screen and (max-width: 400px) {
  .SECOND_IMG {
    width: 100%;
    height: 48vh;
    position: relative;
    display: block;
    margin-bottom: 98px;
  }
  .SECOND_IMG2 {
    width: 100%;
    height: 40vh;
    position: relative;
    display: block;
    margin-bottom: 98px;
  }

  .blue {
    /* padding-top: 5%; */
    position: absolute;
    left: 4%;
    top: 5% !important;
    width: 90%;

  }

  .become {
    position: absolute;
    left: 10%;
    top: 15% !important;
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 19px;
line-height: 23px;
color: #FFFFFF;
  }

  .when {
    position: absolute;
    left: 10%;
    top: 30% !important;
    width: 77%;
    font-family: 'Lato';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 25px;
color: #FFFFFF;

  }
  .res_social{
    padding-top: 100px;
  }

  .how {
    position: absolute;
    left: 10%;
    top: 70% !important;
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 19px;
line-height: 23px;
color: #FFFFFF;
width: 60%;

  }
  .splash {
    position: absolute;
    left: 40%;
    top: 46% !important;
  }

  .AppImg {
    position: absolute;
    left: 10%;
    bottom: -20px !important;

    width: 22%
  }

  .GoogleImg {
    position: absolute;
    left: 35%;
    bottom: -20px !important;

    width: 22%;
  }

  .HowCan_main {
    display: none;
  }

}