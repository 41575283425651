/* Importing all CSS files */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");

@import url("./Home/Header.css");
@import url("./Home/Resposiveness.css");
@import url("./ContactUs//ContectForm.css");
@import url("./About/About.css");
@import url("./About/AboutResponsive.css");
@import url("./FAQ//FrequentlyHome.css");
@import url("../css/Privacy/privacy.css");


/* Defaults */
body {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  background-color: #fff;
  overflow-x: hidden;
}
* {
  font-family: "Poppins", sans-serif !important;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
p {
  margin: 0;
  padding: 0;
}
/* Primary Variables */
:root {
  --primary: "green";
}
#root{
  overflow: hidden;
}
