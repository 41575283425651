.News_centerDiv {
  width: 90%;
  margin: auto;
  padding: 30px 0;
}
.News_main_div {
  background: #f6f6f6;
}
/* body {
  background-color: #f6f6f6;
} */
.news_h1 {
  font-size: 40px;
  font-weight: 300;
  border-bottom: 0.5px solid #000000;
}
.News_card_main_div {
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
}
.News_card_colms {
  width: 50%;
}
.card_xyz {
  color: #a7a7a7 !important;
  font-size: 18px;
  text-decoration: none;
  padding-left: 3px;
}
/* colms 2  */
.News_card_colms2 {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f6f6f6 !important;
}
.News_cards_images {
  width: 188px !important;
  height: 188px !important;
  border-radius: 20px !important;
}
.News_cardImage {
  width: 100% !important;
  height: 468px !important;
}
.news_small_colmns {
  display: flex;
  /* box-shadow: 0px 0px 30px -4px rgb(0 0 0 / 18%); */
  border-radius: 16px;
}
.News_cards {
  display: flex !important;
  align-items: center !important;
  background: #f6f6f6 !important;
}
.card_title_p2 {
  font-size: 20px;
  color: #a7a7a7;
}
.Card_first_img {
  height: 100px;
  height: 56vh;
}
@media screen and (min-width: 0px) and (max-width: 800px) {
  .News_card_colms {
    display: none !important;
  }
  .News_card_colms2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: scroll;
  }
  .News_cards {
    flex-direction: column !important;
  }
  .news_small_colmns {
    margin-bottom: 5%;
    border-radius: 31px;
    margin-right: 24px;
  }
  .News_cards_images {
    width: 243px !important;
    height: 188px !important;
    border-radius: 20px !important;
  }
  .card_title_2 {
    font-size: 19px;
    font-weight: 400;
  }
  .CardContent_card {
    margin-left: 9px !important;
    margin-top: 10px;
  }
}
