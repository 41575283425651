.css-bhp9pd-MuiPaper-root-MuiCard-root {
  box-shadow: inherit !important;
  border-radius: 26px !important;
  box-shadow: 0px 0px 12px 4px rgb(0 0 0 / 11%);
  background: #f6f6f6 !important;
  /* 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important; */
}
.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-disabled {
  opacity: 0.38;
  background-color: #fff !important;
  color: #ffb000 !important;
}
.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root {
  color: #a7a7a7 !important;
}
.css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon {
  width: 2em !important;
  height: 2em !important;
  background-color: #fff !important;
  color: #ffb000 !important;
  padding: 8px 0 !important;
}
.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: rgba(0, 0, 0, 0.08);
  background-color: #ffb000 !important;
  color: #fff !important;
}
.css-o69gx8-MuiCardMedia-root {
  height: 100% !important;
}
/* .foodBlogs_main_div {
} */
/* body {
  background-color: #f6f6f6 !important;
} */
.foodBlogs_main_div {
  background: #f6f6f6;
}
.food_blogs_main {
  width: 90%;
  margin: auto;
}
.card_heading {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 0 0;
}
.food_card_BTN {
  width: 90%;
  margin: auto;
  border: none;
  padding: 10px;
  text-align: center;
  background-color: #ffb000 !important;
  font-size: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  color: #fff;
}
.food_title_h1 {
  text-align: center;
  font-size: 40px;
  padding: 1% 0 2% 0;
}
.CardContent_card {
  margin-left: 20px !important;
  /* border: 1px solid red; */
}
.card_title {
  font-size: 32px;
  font-weight: 600;
}
.card_title_2 {
  font-size: 30px;
  font-weight: 400;
}
.card_title_p {
  font-size: 20px;
  color: #a7a7a7;
  padding-bottom: 5px;
}
.card_title2 {
  font-size: 14px;
  color: #a7a7a7;
  font-weight: 700;
  padding: 10px 0;
  font-style: italic;
}
.food_pagination {
  display: flex;
  justify-content: end;
  padding: 20px 0;
}
.css-46bh2p-MuiCardContent-root {
  padding: 0 !important;
}
@media screen and (min-width: 0px) and (max-width: 600px) {
  .foodBlogs_main_div {
    background-color: #fff;
  }
  .food_blogs_main {
    background-color: #fff !important;
  }
  .food_title_h1 {
    font-size: 14px;
    color: #a7a7a7;
    text-align: left;
  }
  .card_heart {
    width: 2rem;
  }
  .card_title_p {
    font-size: 14px;
  }
  .card_title2 {
    font-size: 12px;
    font-weight: 600;
    padding: 7px 0;
  }
  .card_title {
    font-size: 19px;
    font-weight: 400;
  }
  .food_blogsCard {
    padding: 5px 10px !important;
  }
  .news_h1 {
    font-size: 19px !important;
    color: #9c9c9c;
    padding-bottom: 5px;
  }
}
